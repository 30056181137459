import React from 'react';
import ReactDOM from 'react-dom';
import './scss/style.scss';
import * as serviceWorker from './serviceWorker';
import Amplify from 'aws-amplify';
import awsmobile from './aws-exports';
import './App.css';
import Header from './components/layout/Header';
import Navbar from './components/layout/Navbar';
import Footer from './components/layout/Footer';

import { lazy, mount, route } from 'navi'
import { Router, View } from 'react-navi'
import { withOAuth } from 'aws-amplify-react';

import _ from 'lodash';

import LandingPage from './pages/LandingPage';
import Auth from './pages/Auth';
import TeamM from './pages/TeamM';
import Team from './pages/Team';
import WLTeam18 from './pages/wl/2018/WLTeam';
import WLteams19 from './pages/wl/2019/WLteams19';
import WLTeam from './pages/wl/WLTeam';
import WLTeamM from './pages/wl/WLTeamM';
import WLtransfers from './pages/wl/WLtransfers';
import WSLTeam from './pages/wsl/WSLTeam';
import WSLTeamM from './pages/wsl/WSLTeamM';
import WSLProfile from './pages/wsl/WSLProfile';
import WSLProfileM from './pages/wsl/WSLProfileM';
import WLProfile from './pages/wl/WLProfile';
import WLProfileM from './pages/wl/WLProfileM';
import WLProfileF from './pages/wl/WLProfileF';
import WWCLandingPage from './pages/wwc/2023/WWCLandingPage';
import WWCLandingPage19 from './pages/WWCLandingPage';
import WSLLandingPage from './pages/wsl/WSLLandingPage';
import WLLandingPage from './pages/wl/WLLandingPage';
import TransfersWSL from './pages/wsl/TransfersWSL';
import ARCLandingPage from './pages/arc/ARCLandingPage';

import UWCLLandingPage from './pages/uwcl/UWCLLandingPage';
import UWCLTeam from './pages/uwcl/UWCLTeam';
import UWCLTeamM from './pages/uwcl/UWCLTeamM';
import UWCLStats from './pages/uwcl/UWCLStats';
import UWCLStatsM from './pages/uwcl/UWCLStatsM';
import UWCLLeaderboard from './pages/uwcl/UWCLLeaderboard';
import UWCLLeaderboardM from './pages/uwcl/UWCLLeaderboardM';
import HowToPlayUWCL from './pages/uwcl/HowToPlay';
import HowToPlayUWCLM from './pages/uwcl/HowToPlayM';

import NWSLProfile from './pages/nwsl/NWSLProfile';
import NWSLProfileM from './pages/nwsl/NWSLProfileM';
import NWSLProfiles from './data/nwsl/profiles';
import NWSLTeam from './pages/nwsl/NWSLTeam';
import NWSLTeamM from './pages/nwsl/NWSLTeamM';
import NWSLFixtures from './pages/nwsl/NWSLFixtures';
import NWSLFixturesM from './pages/nwsl/NWSLFixturesM';
import MteamsNWSL from './pages/nwsl/Mteams';
import MteamsWWC23 from './pages/wwc/2023/Mteams';
import DatabaseNWSL from './pages/nwsl/Profiles';
import DatabaseNWSL22 from './pages/nwsl/2022/Profiles';
import DatabaseNWSL23 from './pages/nwsl/2023/Profiles';
import DatabaseNWSLM from './pages/nwsl/ProfilesM';
import NWSLStats from './pages/nwsl/NWSLStats';
import NWSLStats22 from './pages/nwsl/2022/NWSLStats';
import NWSLStats23 from './pages/nwsl/2023/NWSLStats';
import NWSLStatsM from './pages/nwsl/NWSLStatsM';
import MplayersNWSL from './pages/nwsl/Mplayers';
import NWSLLeaderboard from './pages/nwsl/NWSLLeaderboard';
import NWSLLeaderboard22 from './pages/nwsl/2022/NWSLLeaderboard';
import NWSLLeaderboard23 from './pages/nwsl/2023/NWSLLeaderboard';
import NWSLLeaderboardM from './pages/nwsl/NWSLLeaderboardM';
import HowToPlayNWSL from './pages/nwsl/HowToPlay';
import HowToPlayNWSLM from './pages/nwsl/HowToPlayM';
import NWSLLandingPage from './pages/nwsl/NWSLLandingPage';

import EuroLandingPage from './pages/euro/EuroLandingPage';
import EuroFixtures from './pages/euro/EuroFixtures';
import EuroFixturesM from './pages/euro/EuroFixturesM';
import EuroTeam from './pages/euro/EuroTeam';
import EuroTeamM from './pages/euro/EuroTeamM';
import DatabaseEuro from './pages/euro/Profiles';
import DatabaseEuroM from './pages/euro/ProfilesM';
import EuroStats from './pages/euro/EuroStats';
import EuroStatsM from './pages/euro/EuroStatsM';
import EuroLeaderboard from './pages/euro/EuroLeaderboard';
import EuroLeaderboardM from './pages/euro/EuroLeaderboardM';
import HowToPlayEuro from './pages/euro/HowToPlay';
import EuroMatch from './pages/euro/EuroMatch';
import EuroMatchM from './pages/euro/EuroMatchM';

import WWCFixtures from './pages/wwc/2023/WWCFixtures';
import WWCFixturesM from './pages/wwc/2023/WWCFixturesM';
import WWCTeam from './pages/wwc/2023/WWCTeam';
import WWCTeamM from './pages/wwc/2023/WWCTeamM';
import DatabaseWWC from './pages/wwc/2023/Profiles';
import DatabaseWWCM from './pages/wwc/2023/ProfilesM';
import WWCStats from './pages/wwc/2023/WWCStats';
import WWCStatsM from './pages/wwc/2023/WWCStatsM';
import WWCLeaderboard23 from './pages/wwc/2023/WWCLeaderboard';
import WWCLeaderboardM23 from './pages/wwc/2023/WWCLeaderboardM';
import WWCMatch from './pages/wwc/2023/WWCMatch';
import WWCMatchM from './pages/wwc/2023/WWCMatchM';

import DatabaseWSL from './pages/wsl/Profiles';
import DatabaseWSLM from './pages/wsl/ProfilesM';
import DatabaseWL from './pages/wl/Profiles';
import DatabaseWLM from './pages/wl/ProfilesM';
import DatabaseUWCL from './pages/uwcl/Profiles';
import DatabaseUWCLM from './pages/uwcl/ProfilesM';


import WLdatabase19 from './pages/wl/2019/WLdatabase';
import WLdatabase20 from './pages/wl/2020/Profiles';
import ProfilesWSL19 from './pages/wsl/2019/Profiles';
import ProfilesWSL20 from './pages/wsl/2020/Profiles';
import ProfilesWSL21 from './pages/wsl/2021/Profiles';
import ProfilesWSL22 from './pages/wsl/2022/Profiles';
import LeagueTest from './pages/LeagueTest';
import listWL from './pages/wl/transferList';
import StatsWSL from './pages/wsl/2019/Stats';
import WSLStats20 from './pages/wsl/2020/WSLStats';
import WSLStats21 from './pages/wsl/2021/WSLStats';
import WSLStats22 from './pages/wsl/2022/WSLStats';
import WSLStats from './pages/wsl/WSLStats';
import WSLStatsM from './pages/wsl/WSLStatsM';
import WLStats from './pages/wl/WLStats';
import WLStatsM from './pages/wl/WLStatsM';
import StatsWL19 from './pages/wl/2019/Stats';
import StatsWL20 from './pages/wl/2020/WLStats';
import StatsAFLW20 from './pages/aflw/2020/Stats';
import StatsAFLW21 from './pages/aflw/2021/Stats';
import StatsAFLW22 from './pages/aflw/2022/Stats';
import StatsAFLW from './pages/aflw/Stats';
import StatsAFLW20M from './pages/aflw/2020/StatsM';
import StatsAFLW21M from './pages/aflw/2021/StatsM';
import StatsAFLWM from './pages/aflw/StatsM';

import AFLWLandingPage from './pages/aflw/AFLWLandingPage';
import HowToPlayAFLW from './pages/aflw/HowToPlay';
import HowToPlayAFLWM from './pages/aflw/HowToPlayM';
import FriendsWL from './pages/wl/Friends';
import AFLWTeam from './pages/aflw/AFLWTeam';
import AFLWTeamM from './pages/aflw/AFLWTeamM';
import AFLWFixtures from './pages/aflw/AFLWFixtures';
import AFLWFixturesM from './pages/aflw/AFLWFixturesM';
import MyAFLWTeam from './pages/aflw/MyAFLWTeam';
import MyAFLWLeagues from './pages/aflw/MyAFLWLeagues';
import AFLWMatch from './pages/aflw/AFLWMatch';
import AFLWMatchM from './pages/aflw/AFLWMatchM';

import ARG from './data/teams/ARG';
import AUS from './data/teams/AUS';
import BRA from './data/teams/BRA';
import CMR from './data/teams/CMR';
import CAN from './data/teams/CAN';
import CHL from './data/teams/CHL';
import CHN from './data/teams/CHN';
import ENGW from './data/teams/ENG';
import FRAW from './data/teams/FRA';
import DEUW from './data/teams/DEU';
import ITAW from './data/teams/ITA';
import JAM from './data/teams/JAM';
import JPN from './data/teams/JPN';
import KOR from './data/teams/KOR';
import NLDW from './data/teams/NLD';
import NGA from './data/teams/NGA';
import NORW from './data/teams/NOR';
import NZL from './data/teams/NZL';
import SCO from './data/teams/SCO';
import ZAF from './data/teams/ZAF';
import ESPW from './data/teams/ESP';
import SWEW from './data/teams/SWE';
import THA from './data/teams/THA';
import USA from './data/teams/USA';

import ADE18 from './data/wl/2018/teams18/ADE';
import BRI18 from './data/wl/2018/teams18/BRI';
import CBR18 from './data/wl/2018/teams18/CBR';
import MCY18 from './data/wl/2018/teams18/MCY';
import MVC18 from './data/wl/2018/teams18/MVC';
import NEW18 from './data/wl/2018/teams18/NEW';
import PER18 from './data/wl/2018/teams18/PER';
import SYD18 from './data/wl/2018/teams18/SYD';
import WSW18 from './data/wl/2018/teams18/WSW';

import ADE19 from './data/wl/2019/teams/adelaide-united';
import BRI19 from './data/wl/2019/teams/brisbane-roar';
import CBR19 from './data/wl/2019/teams/canberra-united';
import MCY19 from './data/wl/2019/teams/melbourne-city';
import MVC19 from './data/wl/2019/teams/melbourne-victory';
import PER19 from './data/wl/2019/teams/perth-glory';
import SYD19 from './data/wl/2019/teams/sydney-fc';
import WSW19 from './data/wl/2019/teams/western-sydney-wanderers';

import ADE from './data/wl/teamLists/teams/ade';
import BRI from './data/wl/teamLists/teams/bri';
import CBR from './data/wl/teamLists/teams/cbr';
import MCY from './data/wl/teamLists/teams/mcy';
import MVC from './data/wl/teamLists/teams/mvc';
import NEW from './data/wl/teamLists/teams/new';
import PER from './data/wl/teamLists/teams/per';
import SYD from './data/wl/teamLists/teams/syd';
import WSW from './data/wl/teamLists/teams/wsw';
import WEL from './data/wl/teamLists/teams/wel';

import ARS from './data/wsl/teamLists/teams/ars';
import BRH from './data/wsl/teamLists/teams/brh';
import EVE from './data/wsl/teamLists/teams/eve';
import CHE from './data/wsl/teamLists/teams/che';
import CRY from './data/wsl/teamLists/teams/cry';
import AST from './data/wsl/teamLists/teams/ast';
import LEI from './data/wsl/teamLists/teams/lei';
import LIV from './data/wsl/teamLists/teams/liv';
import MCI from './data/wsl/teamLists/teams/mci';
import MUN from './data/wsl/teamLists/teams/mun';
import TOT from './data/wsl/teamLists/teams/tot';
import WHU from './data/wsl/teamLists/teams/whu';

import LAC from './data/nwsl/teamLists/teams/lac';
import CHI from './data/nwsl/teamLists/teams/chi';
import HOU from './data/nwsl/teamLists/teams/hou';
import NJY from './data/nwsl/teamLists/teams/njy';
import KCC from './data/nwsl/teamLists/teams/kcc';
import NCC from './data/nwsl/teamLists/teams/ncc';
import RGN from './data/nwsl/teamLists/teams/rgn';
import ORL from './data/nwsl/teamLists/teams/orl';
import POR from './data/nwsl/teamLists/teams/por';
import LOU from './data/nwsl/teamLists/teams/lou';
import SDW from './data/nwsl/teamLists/teams/sdw';
import WAS from './data/nwsl/teamLists/teams/was';
import BFC from './data/nwsl/teamLists/teams/bay';
import UTR from './data/nwsl/teamLists/teams/utr';

import FCB from './data/uwcl/teamLists/teams/FCB';
import BAY from './data/uwcl/teamLists/teams/BAY';
import CHEU from './data/uwcl/teamLists/teams/CHE';
import LYO from './data/uwcl/teamLists/teams/LYO';
import SGE from './data/uwcl/teamLists/teams/SGE';
import AJA from './data/uwcl/teamLists/teams/AJA';
import ROM from './data/uwcl/teamLists/teams/ROM';
import SLB from './data/uwcl/teamLists/teams/SLB';
import SKN from './data/uwcl/teamLists/teams/SKN';
import SLP from './data/uwcl/teamLists/teams/SLP';
import RBK from './data/uwcl/teamLists/teams/RBK';
import SKB from './data/uwcl/teamLists/teams/SKB';
import PFC from './data/uwcl/teamLists/teams/PFC';
import PSG from './data/uwcl/teamLists/teams/PSG';
import MAD from './data/uwcl/teamLists/teams/MAD';
import HAC from './data/uwcl/teamLists/teams/HAC';


import AUT from './data/euro/teamLists/teams/Austria';
import BEL from './data/euro/teamLists/teams/Belgium';
import DNK from './data/euro/teamLists/teams/Denmark';
import ENG from './data/euro/teamLists/teams/England';
import FIN from './data/euro/teamLists/teams/Finland';
import FRA from './data/euro/teamLists/teams/France';
import DEU from './data/euro/teamLists/teams/Germany';
import ISL from './data/euro/teamLists/teams/Iceland';
import ITA from './data/euro/teamLists/teams/Italy';
import NLD from './data/euro/teamLists/teams/Netherlands';
import NIR from './data/euro/teamLists/teams/Northern Ireland';
import NOR from './data/euro/teamLists/teams/Norway';
import PRT from './data/euro/teamLists/teams/Portugal';
import ESP from './data/euro/teamLists/teams/Spain';
import SWE from './data/euro/teamLists/teams/Sweden';
import CHEW from './data/euro/teamLists/teams/Switzerland';

import ARG23 from './data/wwc/teamLists/teams/Argentina';
import AUS23 from './data/wwc/teamLists/teams/Australia';
import BRA23 from './data/wwc/teamLists/teams/Brazil';
import CAN23 from './data/wwc/teamLists/teams/Canada';
import CHN23 from './data/wwc/teamLists/teams/China';
import COL23 from './data/wwc/teamLists/teams/Colombia';
import CRI23 from './data/wwc/teamLists/teams/Costa Rica';
import DNK23 from './data/wwc/teamLists/teams/Denmark';
import ENG23 from './data/wwc/teamLists/teams/England';
import FRA23 from './data/wwc/teamLists/teams/France';
import DEU23 from './data/wwc/teamLists/teams/Germany';
import HTI23 from './data/wwc/teamLists/teams/Haiti';
import IRL23 from './data/wwc/teamLists/teams/Ireland';
import ITA23 from './data/wwc/teamLists/teams/Italy';
import JAM23 from './data/wwc/teamLists/teams/Jamaica';
import JPN23 from './data/wwc/teamLists/teams/Japan';
import MAR23 from './data/wwc/teamLists/teams/Morocco';
import NLD23 from './data/wwc/teamLists/teams/Netherlands';
import NZL23 from './data/wwc/teamLists/teams/New Zealand';
import NGA23 from './data/wwc/teamLists/teams/Nigeria';
import NOR23 from './data/wwc/teamLists/teams/Norway';
import PAN23 from './data/wwc/teamLists/teams/Panama';
import PHL23 from './data/wwc/teamLists/teams/Philippines';
import PRT23 from './data/wwc/teamLists/teams/Portugal';
import ZAF23 from './data/wwc/teamLists/teams/South Africa';
import KOR23 from './data/wwc/teamLists/teams/South Korea';
import ESP23 from './data/wwc/teamLists/teams/Spain';
import SWE23 from './data/wwc/teamLists/teams/Sweden';
import CHE23 from './data/wwc/teamLists/teams/Switzerland';
import USA23 from './data/wwc/teamLists/teams/USA';
import VNM23 from './data/wwc/teamLists/teams/Vietnam';
import ZMB23 from './data/wwc/teamLists/teams/Zambia';

import AdelaideCrows from './data/aflw/teamLists/teams/adelaide-crows';
import BrisbaneLions from './data/aflw/teamLists/teams/brisbane-lions';
import CarltonBlues from './data/aflw/teamLists/teams/carlton-blues';
import CollingwoodMagpies from './data/aflw/teamLists/teams/collingwood-magpies';
import FremantleDockers from './data/aflw/teamLists/teams/fremantle-dockers';
import GeelongCats from './data/aflw/teamLists/teams/geelong-cats';
import GoldCoastSuns from './data/aflw/teamLists/teams/gold-coast-suns';
import GWSGiants from './data/aflw/teamLists/teams/gws-giants';
import MelbourneDemons from './data/aflw/teamLists/teams/melbourne-demons';
import NorthMelbourneKangaroos from './data/aflw/teamLists/teams/north-melbourne-kangaroos';
import RichmondTigers from './data/aflw/teamLists/teams/richmond-tigers';
import StKildaSaints from './data/aflw/teamLists/teams/st-kilda-saints';
import WestCoastEagles from './data/aflw/teamLists/teams/west-coast-eagles';
import WesternBulldogs from './data/aflw/teamLists/teams/western-bulldogs';
import PortAdelaide from './data/aflw/teamLists/teams/port-adelaide';
import SydneySwans from './data/aflw/teamLists/teams/sydney-swans';
import EssendonBombers from './data/aflw/teamLists/teams/essendon-bombers';
import HawthornHawks from './data/aflw/teamLists/teams/hawthorn-hawks';

import HowToPlay from './pages/HowToPlay';
import WLtemp from './pages/leagues/WLMiniLeagues';
import HowToPlayWWC from './pages/wwc/2023/HowToPlay';
import HowToPlayWWCM from './pages/wwc/2023/HowToPlayM';
import HowToPlayWSL from './pages/wsl/HowToPlay';
import HowToPlayWSLM from './pages/wsl/HowToPlayM';
import HowToPlayWLM from './pages/wl/HowToPlayM';
import HowToPlayWL from './pages/wl/HowToPlayWL';
import HowToPlayEuroM from './pages/euro/HowToPlayM';
import Fixtures from './pages/wwc/Fixtures';
import FixturesM from './pages/wwc/FixturesM';
import About from './pages/About';
import ourTeam from './pages/ourTeamClosed';
import ourTeamWL from './pages/wl/ourTeamWL';
import ourTeamWSL from './pages/wsl/ourTeamWSL';
import ourTeamAFLW from './pages/aflw/ourTeamAFLW';
import Privacy from './pages/Privacy';
import PrivateLeague from './pages/PrivateLeague';
import MyWSLTeam from './pages/wsl/MyWSLTeam';
import MyNWSLTeam from './pages/nwsl/MyNWSLTeam';
import MyEuroTeam from './pages/euro/MyEuroTeam';
import MyUWCLTeam from './pages/uwcl/MyUWCLTeam';
import MyUWCLLeagues from './pages/uwcl/MyUWCLLeagues';
import MyWWCTeam from './pages/wwc/2023/MyWWCTeam';
import TermsOfService from './pages/TermsOfService';
import League from './pages/leagues/League';
import bigLeague from './pages/leagues/bigLeague';
import FravKor from './pages/matches/FravKor';
import DeuvChn from './pages/matches/DeuvChn';
import EspvZaf from './pages/matches/EspvZaf';
import NorvNga from './pages/matches/NorvNga';
import AusvIta from './pages/matches/AusvIta';
import BravJam from './pages/matches/BravJam';
import EngvSco from './pages/matches/EngvSco';
import ArgvJpn from './pages/matches/ArgvJpn';
import CanvCam from './pages/matches/CanvCam';
import NzlvNld from './pages/matches/NzlvNld';
import UsavTha from './pages/matches/UsavTha';
import ChivSwe from './pages/matches/ChivSwe';
import Leaderboard from './pages/wwc/Leaderboard';
import WLLeaderboard18 from './pages/wl/2018/WLdatabase18';
import WLLeaderboard17 from './pages/wl/2017/WLdatabase17';
import WLallPlayers from './pages/wl/WLallPlayers';
import WLallPlayersM from './pages/wl/WLallPlayersM';
import allPlayersWSL from './pages/wsl/WSLallPlayers';
import allPlayersWSLM from './pages/wsl/WSLallPlayersM';
import AFLWLeaderboard2020 from './pages/aflw/2020/AFLWLeaderboard';
import AFLWLeaderboard2021 from './pages/aflw/2021/AFLWLeaderboard';
import AFLWLeaderboard2022 from './pages/aflw/2022/AFLWLeaderboard';
import AFLWLeaderboard from './pages/aflw/AFLWLeaderboard';
import AFLWLeaderboardM from './pages/aflw/AFLWLeaderboardM';
import WWCLeaderboard from './pages/wwc/WWCLeaderboard';
import WWCLeaderboardM from './pages/wwc/WWCLeaderboardM';
import LeaderboardM from './pages/wwc/LeaderboardM';
import WLGLeaderboard18 from './pages/wl/2018/WLGLeaderboard18';
import DynamicMatch from './pages/matches/DynamicMatch';
import WslMatch from './pages/wsl/WSLMatch';
import WslMatchM from './pages/wsl/WSLMatchM';
import UwclMatch from './pages/uwcl/UWCLMatch';
import UwclMatchM from './pages/uwcl/UWCLMatchM';
import WlMatch from './pages/wl/WLMatch';
import WlMatchM from './pages/wl/WLMatchM';
import NwslMatch from './pages/nwsl/NWSLMatch';
import NwslMatchM from './pages/nwsl/NWSLMatchM';
import NameGame from './pages/wwc/NameGame';
import NameGameTest from './pages/wwc/NameGameTest';
import Mteams from './pages/wwc/Mteams';
import MplayersWSL from './pages/wsl/Mplayers';
import MplayersWL from './pages/wl/Mplayers';
import MplayersAFLW from './pages/aflw/Mplayers';
import MplayersWWC from './pages/wwc/2023/Mplayers';
import MteamsWSL from './pages/wsl/Mteams';
import MteamsWL from './pages/wl/Mteams';
import MteamsAFLW from './pages/aflw/Mteams';
import MteamsEuro from './pages/euro/Mteams';
import MteamsUWCL from './pages/uwcl/Mteams';
import MplayersUWCL from './pages/uwcl/Mplayers';
import JoinUs from './pages/JoinUs';
import Feedback from './pages/Feedback';
import FeedbackM from './pages/wwc/FeedbackM';
import WSLLeaderboard19 from './pages/wsl/2019/WSLLeaderboard';
import WSLLeaderboard20 from './pages/wsl/2020/WSLLeaderboard';
import WSLLeaderboard21 from './pages/wsl/2021/WSLLeaderboard';
import WSLLeaderboard22 from './pages/wsl/2022/WSLLeaderboard';
import WSLLeaderboard from './pages/wsl/WSLLeaderboard';
import WLLeaderboard19 from './pages/wl/2019/WLLeaderboard';
import WLLeaderboard20 from './pages/wl/2020/WLLeaderboard';
import WLLeaderboardM from './pages/wl/WLLeaderboardM';
import WLLeaderboard from './pages/wl/WLLeaderboard';
import WSLLeaderboardM from './pages/wsl/WSLLeaderboardM';
import WSLFixtures from './pages/wsl/WSLFixtures';
import WSLFixturesM from './pages/wsl/WSLFixturesM';
import WLFixtures from './pages/wl/WLFixtures';
import WLFixturesM from './pages/wl/WLFixturesM';

import UWCLFixtures from './pages/uwcl/UWCLFixtures';
import UWCLFixturesM from './pages/uwcl/UWCLFixturesM';


import WSLProfiles from './data/wsl/profiles';
import WLProfiles from './data/wl/profiles';
import UWCLProfiles from './data/uwcl/profiles';
import UWCLProfile from './pages/uwcl/UWCLProfile';
import UWCLProfileM from './pages/uwcl/UWCLProfileM';


import MyWLeagueTeam from './pages/wl/MyWLeagueTeam';
import MyWSLLeagues from './pages/wsl/MyWSLLeagues';
import MyWLeagueLeagues from './pages/wl/MyWLeagueLeagues';
import MyNWSLLeagues from './pages/nwsl/MyNWSLLeagues';
import MyEuroLeagues from './pages/euro/MyEuroLeagues';
import MyWWCLeagues from './pages/wwc/2023/MyWWCLeagues';




Amplify.configure({
    ...awsmobile,
    API: {
        endpoints: [
            {
                name: "team",
                endpoint: "https://us6s70fhsc.execute-api.ap-southeast-2.amazonaws.com/prod/team",
                custom_header: async () => {
                    return { Authorization: (await Auth.currentSession()).idToken.jwtToken }
                }
            }
        ]
    }

})

const routes = mount({
    '/': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: LandingPage
    }),
    '/my-team': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: MyWSLTeam
    }),
    '/my-leagues': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: MyWSLLeagues
    }),
    '/my-leagues/:initialCode': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: MyWSLLeagues
    }),
    '/nwsl/my-leagues': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: MyNWSLLeagues
    }),
    '/euro/my-leagues': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: MyEuroLeagues
    }),
    '/wwc/my-leagues': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: MyWWCLeagues
    }),
    '/nwsl/my-leagues/:initialCode': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: MyNWSLLeagues
    }),
    '/nwsl/league/:leagueCode': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: League
    }),
    '/euro/league/:leagueCode': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: League
    }),
    '/uwcl/league/:leagueCode': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: League
    }),
    '/nwsl/league/big/:leagueCode': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: bigLeague
    }),
    '/wwc/league/:leagueCode': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: League
    }),
    '/league/:leagueCode': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: League
    }),
    '/wl/league/:leagueCode': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: League
    }),
    '/wsl/league/:leagueCode': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: League
    }),
    '/wwc/fixtures': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: WWCFixtures
    }),
    '/wwc/m/fixtures': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: WWCFixturesM
    }),
    '/wwc/19/leaderboard': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: Leaderboard
    }),
    '/wwc/19/m/leaderboard': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: LeaderboardM
    }),
    '/wsl/mini-leagues': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: LeagueTest
    }),
    '/wl/20/leaderboard': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: WLLeaderboard20
    }),
    '/wl/18/players': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: WLLeaderboard18
    }),
    '/wl/17/players': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: WLLeaderboard17
    }),
    '/wl/all-players': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: WLallPlayers
    }),
    '/wl/m/all-players': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: WLallPlayersM
    }),
    '/wsl/all-players': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: allPlayersWSL
    }),
    '/wsl/m/all-players': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: allPlayersWSLM
    }),
    '/wwc/19/players': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: WWCLeaderboard
    }),
    '/wwc/leaderboard': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: WWCLeaderboard23
    }),
    '/wwc/m/leaderboard': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: WWCLeaderboardM23
    }),
    '/wwc/19/m/players': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: WWCLeaderboardM
    }),
    '/wwc/19': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: WWCLandingPage19
    }),
    '/wwc': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: WWCLandingPage
    }),
    '/wl/18/leaderboard': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: WLGLeaderboard18
    }),
    '/wsl': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: WSLLandingPage
    }),
    '/uwcl': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: UWCLLandingPage
    }),
    '/arc': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: ARCLandingPage
    }),
    '/nwsl': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: NWSLLandingPage
    }),
    '/euro': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: EuroLandingPage
    }),
    '/wsl/my-team': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: MyWSLTeam
    }),
    '/wsl/transfers': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: TransfersWSL
    }),
    '/wsl/my-leagues': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: MyWSLLeagues
    }),
    '/wsl/my-leagues/:initialCode': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: MyWSLLeagues
    }),
    '/aflw': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: AFLWLandingPage
    }),
    '/aflw/how-to-play': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: HowToPlayAFLW
    }),
    '/aflw/m/how-to-play': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: HowToPlayAFLWM
    }),
    '/wl/friends': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: FriendsWL
    }),
    '/aflw/my-team': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: MyAFLWTeam
    }),
    '/aflw/my-leagues': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: MyAFLWLeagues
    }),
    '/aflw/my-leagues/:initialCode': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: MyAFLWLeagues
    }),
    '/aflw/league/:leagueCode': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: League
    }),
    '/wl/my-leagues': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: MyWLeagueLeagues
    }),
    '/wl/my-leagues/:initialCode': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: MyWLeagueLeagues
    }),
    '/wl/transfers': route({
        title: 'ShePlays',
        view: WLtransfers
    }),
    '/wl/news': route({
        title: 'ShePlays',
        view: listWL
    }),
    '/wsl/19/stats': route({
        title: 'ShePlays',
        view: StatsWSL
    }),
    '/wsl/20/stats': route({
        title: 'ShePlays',
        view: WSLStats20
    }),
    '/wsl/21/stats': route({
        title: 'ShePlays',
        view: WSLStats21
    }),
    '/wsl/22/stats': route({
        title: 'ShePlays',
        view: WSLStats22
    }),
    '/wsl/stats': route({
        title: 'ShePlays',
        view: WSLStats
    }),
    '/wsl/m/stats': route({
        title: 'ShePlays',
        view: WSLStatsM
    }),
    '/nwsl/stats': route({
        title: 'ShePlays',
        view: NWSLStats
    }),
    '/nwsl/22/stats': route({
        title: 'ShePlays',
        view: NWSLStats22
    }),
    '/nwsl/23/stats': route({
        title: 'ShePlays',
        view: NWSLStats23
    }),
    '/nwsl/m/stats': route({
        title: 'ShePlays',
        view: NWSLStatsM
    }),
    '/uwcl/stats': route({
        title: 'ShePlays',
        view: UWCLStats
    }),
    '/uwcl/m/stats': route({
        title: 'ShePlays',
        view: UWCLStatsM
    }),
    '/euro/stats': route({
        title: 'ShePlays',
        view: EuroStats
    }),
    '/euro/m/stats': route({
        title: 'ShePlays',
        view: EuroStatsM
    }),
    '/wwc/stats': route({
        title: 'ShePlays',
        view: WWCStats
    }),
    '/wwc/m/stats': route({
        title: 'ShePlays',
        view: WWCStatsM
    }),
    '/wl/stats': route({
        title: 'ShePlays',
        view: WLStats
    }),
    '/wl/m/stats': route({
        title: 'ShePlays',
        view: WLStatsM
    }),
    '/wl/19/stats': route({
        title: 'ShePlays',
        view: StatsWL19
    }),
    '/wl/20/stats': route({
        title: 'ShePlays',
        view: StatsWL20
    }),
    '/aflw/2020/stats': route({
        title: 'ShePlays',
        view: StatsAFLW20
    }),
    '/aflw/2021/stats': route({
        title: 'ShePlays',
        view: StatsAFLW21
    }),
    '/aflw/2022/stats': route({
        title: 'ShePlays',
        view: StatsAFLW22
    }),
    '/aflw//stats': route({
        title: 'ShePlays',
        view: StatsAFLW
    }),
    '/aflw/m/2020/stats': route({
        title: 'ShePlays',
        view: StatsAFLW20M
    }),
    '/aflw/m/2021/stats': route({
        title: 'ShePlays',
        view: StatsAFLW21M
    }),
    '/aflw/m/stats': route({
        title: 'ShePlays',
        view: StatsAFLWM
    }),
    '/wsl/fixtures': route({
        title: 'ShePlays',
        view: WSLFixtures
    }),
    '/wsl/m/fixtures': route({
        title: 'ShePlays',
        view: WSLFixturesM
    }),
    '/uwcl/fixtures': route({
        title: 'ShePlays',
        view: UWCLFixtures
    }),
    '/uwcl/m/fixtures': route({
        title: 'ShePlays',
        view: UWCLFixturesM
    }),
    '/wl/fixtures': route({
        title: 'ShePlays',
        view: WLFixtures
    }),
    '/wl/m/fixtures': route({
        title: 'ShePlays',
        view: WLFixturesM
    }),
    '/nwsl/fixtures': route({
        title: 'ShePlays',
        view: NWSLFixtures
    }),
    '/nwsl/m/fixtures': route({
        title: 'ShePlays',
        view: NWSLFixturesM
    }),
    '/euro/fixtures': route({
        title: 'ShePlays',
        view: EuroFixtures
    }),
    '/euro/m/fixtures': route({
        title: 'ShePlays',
        view: EuroFixturesM
    }),
    '/wl/how-to-play': route({
        title: 'ShePlays',
        view: HowToPlayWL
    }),
    '/wsl/database': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: DatabaseWSL
    }),
    '/wsl/m/database': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: DatabaseWSLM
    }),
    '/uwcl/database': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: DatabaseUWCL
    }),
    '/uwcl/m/database': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: DatabaseUWCLM
    }),
    '/wwc/database': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: DatabaseWWC
    }),
    '/wwc/m/database': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: DatabaseWWCM
    }),
    '/nwsl/database': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: DatabaseNWSL
    }),
    '/nwsl/22/database': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: DatabaseNWSL22
    }),
    '/nwsl/23/database': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: DatabaseNWSL23
    }),
    '/nwsl/m/database': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: DatabaseNWSLM
    }),
    '/euro/database': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: DatabaseEuro
    }),
    '/euro/m/players': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: DatabaseEuroM
    }),
    '/wl/database': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: DatabaseWL
    }),
    '/wl/m/database': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: DatabaseWLM
    }),
    '/wl/19/players': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: WLdatabase19
    }),
    '/wl/20/players': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: WLdatabase20
    }),
    '/wsl/19/profiles': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: ProfilesWSL19
    }),
    '/wsl/20/profiles': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: ProfilesWSL20
    }),
    '/wsl/21/profiles': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: ProfilesWSL21
    }),
    '/wsl/22/profiles': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: ProfilesWSL22
    }),
    '/wsl/19/leaderboard': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: WSLLeaderboard19
    }),
    '/wsl/20/leaderboard': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: WSLLeaderboard20
    }),
    '/wsl/21/leaderboard': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: WSLLeaderboard21
    }),
    '/wsl/22/leaderboard': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: WSLLeaderboard22
    }),
    '/wsl/leaderboard': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: WSLLeaderboard 
    }),
    '/wsl/m/leaderboard': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: WSLLeaderboardM
    }),
    '/nwsl/leaderboard': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: NWSLLeaderboard
    }),
    '/uwcl/leaderboard': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: UWCLLeaderboard
    }),
    '/uwcl/m/leaderboard': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: UWCLLeaderboardM
    }),
    '/nwsl/22/leaderboard': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: NWSLLeaderboard22
    }),
    '/nwsl/23/leaderboard': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: NWSLLeaderboard23
    }),
    '/nwsl/m/leaderboard': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: NWSLLeaderboardM
    }),
    '/euro/leaderboard': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: EuroLeaderboard
    }),
    '/euro/m/leaderboard': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: EuroLeaderboardM
    }),
    '/wl/19/leaderboard': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: WLLeaderboard19
    }),
    '/wl/leaderboard': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: WLLeaderboard
    }),
    '/wl/m/leaderboard': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: WLLeaderboardM
    }),
    '/aflw/2020/leaderboard': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: AFLWLeaderboard2020
    }),
    '/aflw/2021/leaderboard': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: AFLWLeaderboard2021
    }),
    '/aflw/2022/leaderboard': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: AFLWLeaderboard2022
    }),
    '/aflw/leaderboard': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: AFLWLeaderboard
    }),
    '/aflw/m/leaderboard': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: AFLWLeaderboardM
    }),
    '/wl': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: WLLandingPage
    }),
    '/wl/my-team': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: MyWLeagueTeam
    }),
    '/nwsl/my-team': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: MyNWSLTeam
    }),
    '/euro/my-team': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: MyEuroTeam
    }),
    '/uwcl/my-team': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: MyUWCLTeam
    }),
    '/uwcl/my-leagues': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: MyUWCLLeagues
    }),
    '/wwc/my-team': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: MyWWCTeam
    }),
    '/wl/wl-leagues': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: WLtemp
    }),
    '/how-to-play': route({
        title: 'ShePlays | How to Play',
        view: HowToPlay
    }),
    '/wwc/how-to-play': route({
        title: 'ShePlays | How to Play',
        view: HowToPlayWWC
    }),
    '/wwc/m/how-to-play': route({
        title: 'ShePlays | How to Play',
        view: HowToPlayWWCM
    }),
    '/wsl/how-to-play': route({
        title: 'ShePlays | How to Play',
        view: HowToPlayWSL
    }),
    '/wsl/m/how-to-play': route({
        title: 'ShePlays | How to Play',
        view: HowToPlayWSLM
    }),
    '/nwsl/how-to-play': route({
        title: 'ShePlays | How to Play',
        view: HowToPlayNWSL
    }),
    '/nwsl/m/how-to-play': route({
        title: 'ShePlays | How to Play',
        view: HowToPlayNWSLM
    }),
    '/uwcl/how-to-play': route({
        title: 'ShePlays | How to Play',
        view: HowToPlayUWCL
    }),
    '/uwcl/m/how-to-play': route({
        title: 'ShePlays | How to Play',
        view: HowToPlayUWCLM
    }),
    '/euro/how-to-play': route({
        title: 'ShePlays | How to Play',
        view: HowToPlayEuro
    }),
    '/euro/m/how-to-play': route({
        title: 'ShePlays | How to Play',
        view: HowToPlayEuroM
    }),
    '/wl/m/how-to-play': route({
        title: 'ShePlays | How to Play',
        view: HowToPlayWLM
    }),
    '/wwc/19/fixtures': route({
        title: 'ShePlays | Fixtures',
        view: Fixtures
    }),
    '/wwc/19/m/fixtures': route({
        title: 'ShePlays | Fixtures',
        view: FixturesM
    }),
    '/about': route({
        title: 'ShePlays | About',
        view: About
    }),
    '/sheplays-team': route({
        title: 'ShePlays | Our Team',
        view: ourTeam
    }),
    '/wl/sheplays-team': route({
        title: 'ShePlays | Our Team',
        view: ourTeamWL
    }),
    '/privacy': route({
        title: 'ShePlays | Privacy',
        view: Privacy
    }),
    '/match/:id/:friendlyUrlText': route({
        title: 'ShePlays | Match Result',
        view: DynamicMatch
    }),
    '/wsl/match/:id/:friendlyUrlText': route({
        title: 'ShePlays | Match Result',
        view: WslMatch
    }),
    '/wsl/match/:id/:friendlyUrlText/m': route({
        title: 'ShePlays | Match Result',
        view: WslMatchM
    }),
    '/uwcl/match/:id/:friendlyUrlText': route({
        title: 'ShePlays | Match Result',
        view: UwclMatch
    }),
    '/uwcl/match/:id/:friendlyUrlText/m': route({
        title: 'ShePlays | Match Result',
        view: UwclMatchM
    }),
    '/nwsl/match/:id/:friendlyUrlText': route({
        title: 'ShePlays | Match Result',
        view: NwslMatch
    }),
    '/nwsl/match/:id/:friendlyUrlText/m': route({
        title: 'ShePlays | Match Result',
        view: NwslMatchM
    }),
    '/euro/match/:id/:friendlyUrlText': route({
        title: 'ShePlays | Match Result',
        view: EuroMatch
    }),
    '/euro/match/:id/:friendlyUrlText/m': route({
        title: 'ShePlays | Match Result',
        view: EuroMatchM
    }),
    '/wwc/match/:id/:friendlyUrlText': route({
        title: 'ShePlays | Match Result',
        view: WWCMatch
    }),
    '/wwc/match/:id/:friendlyUrlText/m': route({
        title: 'ShePlays | Match Result',
        view: WWCMatchM
    }),
    '/wl/match/:id/:friendlyUrlText': route({
        title: 'ShePlays | Match Result',
        view: WlMatch
    }),
    '/wl/match/:id/:friendlyUrlText/m': route({
        title: 'ShePlays | Match Result',
        view: WlMatchM
    }),
    '/aflw/match/:id/:friendlyUrlText': route({
        title: 'ShePlays | Match Result',
        view: AFLWMatch
    }),
    '/aflw/match/:id/:friendlyUrlText/m': route({
        title: 'ShePlays | Match Result',
        view: AFLWMatchM
    }),
    '/fra-kor': route({
        title: 'ShePlays | France v Korea',
        view: FravKor
    }),
    '/deu-chn': route({
        title: 'ShePlays | Germany v China',
        view: DeuvChn
    }),
    '/esp-zaf': route({
        title: 'ShePlays | Spain v South Africa',
        view: EspvZaf
    }),
    '/nor-nga': route({
        title: 'ShePlays | Norway v Nigeria',
        view: NorvNga
    }),
    '/aus-ita': route({
        title: 'ShePlays | Australia v Italy',
        view: AusvIta
    }),
    '/eng-sco': route({
        title: 'ShePlays | England v Scotland',
        view: EngvSco
    }),
    '/bra-jam': route({
        title: 'ShePlays | Brazil v Jamaica',
        view: BravJam
    }),
    '/arg-jpn': route({
        title: 'ShePlays | Argentina v Japan',
        view: ArgvJpn
    }),
    '/can-cmr': route({
        title: 'ShePlays | Canada v Cameroon',
        view: CanvCam
    }),
    '/nzl-ned': route({
        title: 'ShePlays | New Zealand v Netherlands',
        view: NzlvNld
    }),
    '/usa-tha': route({
        title: 'ShePlays | USA v Thailand',
        view: UsavTha
    }),
    '/chi-swe': route({
        title: 'ShePlays | China v Sweden',
        view: ChivSwe
    }),
    '/terms-of-service': route({
        title: 'ShePlays | Terms of Service',
        view: TermsOfService
    }),
    '/private-league': route({
        title: 'ShePlays | Private League',
        view: PrivateLeague
    }),
    '/auth': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: withOAuth(Auth)
    }),
    '/wwc/19/name-game': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: NameGame
    }),
    '/wwc/19/name-game-test': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: NameGameTest
    }),
    '/wwc/19/teams': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: Mteams
    }),
    '/wwc/teams': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: MteamsWWC23
    }),
    '/wsl/teams': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: MteamsWSL
    }),
    '/wl/teams': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: MteamsWL
    }),
    '/aflw/teams': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: MteamsAFLW
    }),
    '/euro/teams': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: MteamsEuro
    }),
    '/uwcl/teams': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: MteamsUWCL
    }),
    '/nwsl/teams': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: MteamsNWSL
    }),
    '/wsl/m/players': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: MplayersWSL
    }),
    '/uwcl/m/players': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: MplayersUWCL
    }),
    '/wwc/m/players': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: MplayersWWC
    }),
    '/nwsl/m/players': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: MplayersNWSL
    }),
    '/wl/m/players': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: MplayersWL
    }),
    '/aflw/m/players': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: MplayersAFLW
    }),
    '/join-us': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: ourTeam
    }),
    '/wl/join-us': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: ourTeamWL
    }),
    '/wsl/join-us': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: ourTeamWSL
    }),
    '/aflw/join-us': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: ourTeamAFLW
    }),
    '/wwc/19/feedback': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: Feedback
    }),
    '/wwc/19/m/feedback': route({
        title: 'ShePlays | Fantasy Football Competition',
        view: FeedbackM
    }),
    '/wwc/19/teams/arg': route({
        title: 'ShePlays | Argentina (WWC)',
        view: <Team team={ARG} />
    }),
    '/wwc/19/teams/aus': route({
        title: 'ShePlays | England (WWC)',
        view: <Team team={AUS} />
    }),
    '/wwc/19/teams/bra': route({
        title: 'ShePlays | Brazil (WWC)',
        view: <Team team={BRA} />
    }),
    '/wwc/19/teams/cmr': route({
        title: 'ShePlays | Cameroon (WWC)',
        view: <Team team={CMR} />
    }),
    '/wwc/19/teams/can': route({
        title: 'ShePlays | Canada (WWC)',
        view: <Team team={CAN} />
    }),
    '/wwc/19/teams/chl': route({
        title: 'ShePlays | Chile (WWC)',
        view: <Team team={CHL} />
    }),
    '/wwc/teams/chn': route({
        title: 'ShePlays | China (WWC)',
        view: <Team team={CHN} />
    }),
    '/wwc/teams/eng': route({
        title: 'ShePlays | England (WWC)',
        view: <Team team={ENGW} />
    }),
    '/wwc/teams/fra': route({
        title: 'ShePlays | France (WWC)',
        view: <Team team={FRAW} />
    }),
    '/wwc/teams/deu': route({
        title: 'ShePlays | Germany (WWC)',
        view: <Team team={DEUW} />
    }),
    '/wwc/teams/ita': route({
        title: 'ShePlays | Italy (WWC)',
        view: <Team team={ITAW} />
    }),
    '/wwc/teams/jam': route({
        title: 'ShePlays | Jamaica (WWC)',
        view: <Team team={JAM} />
    }),
    '/wwc/teams/m/arg': route({
        title: 'ShePlays | Argentina (WWC)',
        view: <TeamM team={ARG} />
    }),
    '/wwc/teams/m/aus': route({
        title: 'ShePlays | England (WWC)',
        view: <TeamM team={AUS} />
    }),
    '/wwc/teams/m/bra': route({
        title: 'ShePlays | Brazil (WWC)',
        view: <TeamM team={BRA} />
    }),
    '/wwc/teams/m/cmr': route({
        title: 'ShePlays | Cameroon (WWC)',
        view: <TeamM team={CMR} />
    }),
    '/wwc/teams/m/can': route({
        title: 'ShePlays | Canada (WWC)',
        view: <TeamM team={CAN} />
    }),
    '/wwc/teams/m/chl': route({
        title: 'ShePlays | Chile (WWC)',
        view: <TeamM team={CHL} />
    }),
    '/wwc/teams/m/chn': route({
        title: 'ShePlays | China (WWC)',
        view: <TeamM team={CHN} />
    }),
    '/wwc/teams/m/eng': route({
        title: 'ShePlays | England (WWC)',
        view: <TeamM team={ENGW} />
    }),
    '/wwc/teams/m/fra': route({
        title: 'ShePlays | France (WWC)',
        view: <TeamM team={FRAW} />
    }),
    '/wwc/teams/m/deu': route({
        title: 'ShePlays | Germany (WWC)',
        view: <TeamM team={DEUW} />
    }),
    '/wwc/teams/m/ita': route({
        title: 'ShePlays | Italy (WWC)',
        view: <TeamM team={ITAW} />
    }),
    '/wwc/teams/m/jam': route({
        title: 'ShePlays | Jamaica (WWC)',
        view: <TeamM team={JAM} />
    }),
    '/wwc/teams/jpn': route({
        title: 'ShePlays | Japan (WWC)',
        view: <Team team={JPN} />
    }),
    '/wwc/teams/kor': route({
        title: 'ShePlays | Korea (WWC)',
        view: <Team team={KOR} />
    }),
    '/wwc/teams/nld': route({
        title: 'ShePlays | Netherlands (WWC)',
        view: <Team team={NLDW} />
    }),
    '/wwc/teams/nga': route({
        title: 'ShePlays | Nigeria (WWC)',
        view: <Team team={NGA} />
    }),
    '/wwc/teams/nor': route({
        title: 'ShePlays | Norway (WWC)',
        view: <Team team={NORW} />
    }),
    '/wwc/teams/nzl': route({
        title: 'ShePlays | New Zealand (WWC)',
        view: <Team team={NZL} />
    }),
    '/wwc/teams/sco': route({
        title: 'ShePlays | Scotland (WWC)',
        view: <Team team={SCO} />
    }),
    '/wwc/teams/zaf': route({
        title: 'ShePlays | South Africa (WWC)',
        view: <Team team={ZAF} />
    }),
    '/wwc/teams/esp': route({
        title: 'ShePlays | Spain (WWC)',
        view: <Team team={ESPW} />
    }),
    '/wwc/teams/swe': route({
        title: 'ShePlays | Sweden (WWC)',
        view: <Team team={SWEW} />
    }),
    '/wwc/teams/tha': route({
        title: 'ShePlays | Thailand (WWC)',
        view: <Team team={THA} />
    }),
    '/wwc/teams/usa': route({
        title: 'ShePlays | United States (WWC)',
        view: <Team team={USA} />
    }),
    '/wwc/teams/m/jpn': route({
        title: 'ShePlays | Japan (WWC)',
        view: <TeamM team={JPN} />
    }),
    '/wwc/teams/m/kor': route({
        title: 'ShePlays | Korea (WWC)',
        view: <TeamM team={KOR} />
    }),
    '/wwc/teams/m/nld': route({
        title: 'ShePlays | Netherlands (WWC)',
        view: <TeamM team={NLDW} />
    }),
    '/wwc/teams/m/nga': route({
        title: 'ShePlays | Nigeria (WWC)',
        view: <TeamM team={NGA} />
    }),
    '/wwc/teams/m/nor': route({
        title: 'ShePlays | Norway (WWC)',
        view: <TeamM team={NORW} />
    }),
    '/wwc/teams/m/nzl': route({
        title: 'ShePlays | New Zealand (WWC)',
        view: <TeamM team={NZL} />
    }),
    '/wwc/teams/m/sco': route({
        title: 'ShePlays | Scotland (WWC)',
        view: <TeamM team={SCO} />
    }),
    '/wwc/teams/m/zaf': route({
        title: 'ShePlays | South Africa (WWC)',
        view: <TeamM team={ZAF} />
    }),
    '/wwc/teams/m/esp': route({
        title: 'ShePlays | Spain (WWC)',
        view: <TeamM team={ESPW} />
    }),
    '/wwc/teams/m/swe': route({
        title: 'ShePlays | Sweden (WWC)',
        view: <TeamM team={SWEW} />
    }),
    '/wwc/teams/m/tha': route({
        title: 'ShePlays | Thailand (WWC)',
        view: <TeamM team={THA} />
    }),
    '/wwc/teams/m/usa': route({
        title: 'ShePlays | United States (WWC)',
        view: <TeamM team={USA} />
    }),
    '/wsl/teams/ars': route({
        title: 'ShePlays | Arsenal',
        view: <WSLTeam wslteam={ARS} />
    }),
    '/wsl/teams/cry': route({
        title: 'ShePlays | Crystal Palace',
        view: <WSLTeam wslteam={CRY} />
    }),
    '/wsl/teams/brh': route({
        title: 'ShePlays | Brighton Hove Albion',
        view: <WSLTeam wslteam={BRH} />
    }),
    '/wsl/teams/lei': route({
        title: 'ShePlays | Leicester City',
        view: <WSLTeam wslteam={LEI} />
    }),
    '/wsl/teams/liv': route({
        title: 'ShePlays | Liverpool',
        view: <WSLTeam wslteam={LIV} />
    }),
    '/wsl/teams/eve': route({
        title: 'ShePlays | Everton',
        view: <WSLTeam wslteam={EVE} />
    }),
    '/wsl/teams/che': route({
        title: 'ShePlays | Chelsea',
        view: <WSLTeam wslteam={CHE} />
    }),
    '/wsl/teams/ast': route({
        title: 'ShePlays | Aston Villa',
        view: <WSLTeam wslteam={AST} />
    }),
    '/wsl/teams/mci': route({
        title: 'ShePlays | Manchester City',
        view: <WSLTeam wslteam={MCI} />
    }),
    '/wsl/teams/mun': route({
        title: 'ShePlays | Manchester United',
        view: <WSLTeam wslteam={MUN} />
    }),
    '/wsl/teams/tot': route({
        title: 'ShePlays | Tottenham Hotspur',
        view: <WSLTeam wslteam={TOT} />
    }),
    '/wsl/teams/whu': route({
        title: 'ShePlays | West Ham United',
        view: <WSLTeam wslteam={WHU} />
    }),
    '/wsl/teams/m/ars': route({
        title: 'ShePlays | Arsenal',
        view: <WSLTeamM wslteam={ARS} />
    }),
    '/wsl/teams/m/cry': route({
        title: 'ShePlays | Crystal Palace',
        view: <WSLTeamM wslteam={CRY} />
    }),
    '/wsl/teams/m/brh': route({
        title: 'ShePlays | Brighton Hove Albion',
        view: <WSLTeamM wslteam={BRH} />
    }),
    '/wsl/teams/m/lei': route({
        title: 'ShePlays | Leicester City',
        view: <WSLTeamM wslteam={LEI} />
    }),
    '/wsl/teams/m/liv': route({
        title: 'ShePlays | Liverpool',
        view: <WSLTeamM wslteam={LIV} />
    }),
    '/wsl/teams/m/eve': route({
        title: 'ShePlays | Everton',
        view: <WSLTeamM wslteam={EVE} />
    }),
    '/wsl/teams/m/che': route({
        title: 'ShePlays | Chelsea',
        view: <WSLTeamM wslteam={CHE} />
    }),
    '/wsl/teams/m/ast': route({
        title: 'ShePlays | Aston Villa',
        view: <WSLTeamM wslteam={AST} />
    }),
    '/wsl/teams/m/mci': route({
        title: 'ShePlays | Manchester City',
        view: <WSLTeamM wslteam={MCI} />
    }),
    '/wsl/teams/m/mun': route({
        title: 'ShePlays | Manchester United',
        view: <WSLTeamM wslteam={MUN} />
    }),
    '/wsl/teams/m/tot': route({
        title: 'ShePlays | Tottenham Hotspur',
        view: <WSLTeamM wslteam={TOT} />
    }),
    '/wsl/teams/m/whu': route({
        title: 'ShePlays | West Ham United',
        view: <WSLTeamM wslteam={WHU} />
    }),
    '/nwsl/teams/lac': route({
        title: 'ShePlays | Angel City FC',
        view: <NWSLTeam nwslteam={LAC} />
    }),
    '/nwsl/teams/chi': route({
        title: 'ShePlays | Chicago Red Stars',
        view: <NWSLTeam nwslteam={CHI} />
    }),
    '/nwsl/teams/hou': route({
        title: 'ShePlays | Houston Dash',
        view: <NWSLTeam nwslteam={HOU} />
    }),
    '/nwsl/teams/kcc': route({
        title: 'ShePlays | Kansas City Current',
        view: <NWSLTeam nwslteam={KCC} />
    }),
    '/nwsl/teams/njy': route({
        title: 'ShePlays | NJ/NY Gotham FC',
        view: <NWSLTeam nwslteam={NJY} />
    }),
    '/nwsl/teams/ncc': route({
        title: 'ShePlays | North Carolina Courage',
        view: <NWSLTeam nwslteam={NCC} />
    }),
    '/nwsl/teams/rgn': route({
        title: 'ShePlays | OL Reign',
        view: <NWSLTeam nwslteam={RGN} />
    }),
    '/nwsl/teams/orl': route({
        title: 'ShePlays | Orlando Pride',
        view: <NWSLTeam nwslteam={ORL} />
    }),
    '/nwsl/teams/por': route({
        title: 'ShePlays | Portland Thorns',
        view: <NWSLTeam nwslteam={POR} />
    }),
    '/nwsl/teams/lou': route({
        title: 'ShePlays | Racing Louisville FC',
        view: <NWSLTeam nwslteam={LOU} />
    }),
    '/nwsl/teams/sdw': route({
        title: 'ShePlays | San Diego Wave',
        view: <NWSLTeam nwslteam={SDW} />
    }),
    '/nwsl/teams/bay': route({
        title: 'ShePlays | Bay FC',
        view: <NWSLTeam nwslteam={BFC} />
    }),
    '/nwsl/teams/utr': route({
        title: 'ShePlays | Utah Royals FC',
        view: <NWSLTeam nwslteam={UTR} />
    }),
    '/nwsl/teams/was': route({
        title: 'ShePlays | Washington Spirit',
        view: <NWSLTeam nwslteam={WAS} />
    }),
    '/nwsl/m/teams/lac': route({
        title: 'ShePlays | Angel City FC',
        view: <NWSLTeamM nwslteam={LAC} />
    }),
    '/nwsl/m/teams/chi': route({
        title: 'ShePlays | Chicago Red Stars',
        view: <NWSLTeamM nwslteam={CHI} />
    }),
    '/nwsl/m/teams/hou': route({
        title: 'ShePlays | Houston Dash',
        view: <NWSLTeamM nwslteam={HOU} />
    }),
    '/nwsl/m/teams/kcc': route({
        title: 'ShePlays | Kansas City Current',
        view: <NWSLTeamM nwslteam={KCC} />
    }),
    '/nwsl/m/teams/njy': route({
        title: 'ShePlays | NJ/NY Gotham FC',
        view: <NWSLTeamM nwslteam={NJY} />
    }),
    '/nwsl/m/teams/ncc': route({
        title: 'ShePlays | North Carolina Courage',
        view: <NWSLTeamM nwslteam={NCC} />
    }),
    '/nwsl/m/teams/rgn': route({
        title: 'ShePlays | OL Reign',
        view: <NWSLTeamM nwslteam={RGN} />
    }),
    '/nwsl/m/teams/orl': route({
        title: 'ShePlays | Orlando Pride',
        view: <NWSLTeamM nwslteam={ORL} />
    }),
    '/nwsl/m/teams/por': route({
        title: 'ShePlays | Portland Thorns',
        view: <NWSLTeamM nwslteam={POR} />
    }),
    '/nwsl/m/teams/lou': route({
        title: 'ShePlays | Racing Louisville FC',
        view: <NWSLTeamM nwslteam={LOU} />
    }),
    '/nwsl/m/teams/sdw': route({
        title: 'ShePlays | San Diego Wave',
        view: <NWSLTeamM nwslteam={SDW} />
    }),
    '/nwsl/m/teams/bay': route({
        title: 'ShePlays | Bay FC',
        view: <NWSLTeamM nwslteam={BFC} />
    }),
    '/nwsl/m/teams/utr': route({
        title: 'ShePlays | Utah Royals FC',
        view: <NWSLTeamM nwslteam={UTR} />
    }),
    '/nwsl/m/teams/was': route({
        title: 'ShePlays | Washington Spirit',
        view: <NWSLTeamM nwslteam={WAS} />
    }),
    '/uwcl/teams/fcb': route({
        title: 'ShePlays | Washington Spirit',
        view: <UWCLTeam uwclteam={FCB} />
    }),
    '/uwcl/teams/bay': route({
        title: 'ShePlays | Washington Spirit',
        view: <UWCLTeam uwclteam={BAY} />
    }),
    '/uwcl/teams/che': route({
        title: 'ShePlays | Washington Spirit',
        view: <UWCLTeam uwclteam={CHEU} />
    }),
    '/uwcl/teams/lyo': route({
        title: 'ShePlays | Washington Spirit',
        view: <UWCLTeam uwclteam={LYO} />
    }),
    '/uwcl/teams/sge': route({
        title: 'ShePlays | Washington Spirit',
        view: <UWCLTeam uwclteam={SGE} />
    }),
    '/uwcl/teams/aja': route({
        title: 'ShePlays | Washington Spirit',
        view: <UWCLTeam uwclteam={AJA} />
    }),
    '/uwcl/teams/rom': route({
        title: 'ShePlays | Washington Spirit',
        view: <UWCLTeam uwclteam={ROM} />
    }),
    '/uwcl/teams/slb': route({
        title: 'ShePlays | Washington Spirit',
        view: <UWCLTeam uwclteam={SLB} />
    }),
    '/uwcl/teams/skn': route({
        title: 'ShePlays | Washington Spirit',
        view: <UWCLTeam uwclteam={SKN} />
    }),
    '/uwcl/teams/slp': route({
        title: 'ShePlays | Washington Spirit',
        view: <UWCLTeam uwclteam={SLP} />
    }),
    '/uwcl/teams/rbk': route({
        title: 'ShePlays | Washington Spirit',
        view: <UWCLTeam uwclteam={RBK} />
    }),
    '/uwcl/teams/skb': route({
        title: 'ShePlays | Washington Spirit',
        view: <UWCLTeam uwclteam={SKB} />
    }),
    '/uwcl/teams/pfc': route({
        title: 'ShePlays | Washington Spirit',
        view: <UWCLTeam uwclteam={PFC} />
    }),
    '/uwcl/teams/psg': route({
        title: 'ShePlays | Washington Spirit',
        view: <UWCLTeam uwclteam={PSG} />
    }),
    '/uwcl/teams/mad': route({
        title: 'ShePlays | Washington Spirit',
        view: <UWCLTeam uwclteam={MAD} />
    }),
    '/uwcl/teams/hac': route({
        title: 'ShePlays | Washington Spirit',
        view: <UWCLTeam uwclteam={HAC} />
    }),
    '/uwcl/teams/m/fcb': route({
        title: 'ShePlays | Washington Spirit',
        view: <UWCLTeamM uwclteam={FCB} />
    }),
    '/uwcl/teams/m/bay': route({
        title: 'ShePlays | Washington Spirit',
        view: <UWCLTeamM uwclteam={BAY} />
    }),
    '/uwcl/teams/m/che': route({
        title: 'ShePlays | Washington Spirit',
        view: <UWCLTeamM uwclteam={CHEU} />
    }),
    '/uwcl/teams/m/lyo': route({
        title: 'ShePlays | Washington Spirit',
        view: <UWCLTeamM uwclteam={LYO} />
    }),
    '/uwcl/teams/m/sge': route({
        title: 'ShePlays | Washington Spirit',
        view: <UWCLTeamM uwclteam={SGE} />
    }),
    '/uwcl/teams/m/aja': route({
        title: 'ShePlays | Washington Spirit',
        view: <UWCLTeamM uwclteam={AJA} />
    }),
    '/uwcl/teams/m/rom': route({
        title: 'ShePlays | Washington Spirit',
        view: <UWCLTeamM uwclteam={ROM} />
    }),
    '/uwcl/teams/m/slb': route({
        title: 'ShePlays | Washington Spirit',
        view: <UWCLTeamM uwclteam={SLB} />
    }),
    '/uwcl/teams/m/skn': route({
        title: 'ShePlays | Washington Spirit',
        view: <UWCLTeamM uwclteam={SKN} />
    }),
    '/uwcl/teams/m/slp': route({
        title: 'ShePlays | Washington Spirit',
        view: <UWCLTeamM uwclteam={SLP} />
    }),
    '/uwcl/teams/m/rbk': route({
        title: 'ShePlays | Washington Spirit',
        view: <UWCLTeamM uwclteam={RBK} />
    }),
    '/uwcl/teams/m/skb': route({
        title: 'ShePlays | Washington Spirit',
        view: <UWCLTeamM uwclteam={SKB} />
    }),
    '/uwcl/teams/m/pfc': route({
        title: 'ShePlays | Washington Spirit',
        view: <UWCLTeamM uwclteam={PFC} />
    }),
    '/uwcl/teams/m/psg': route({
        title: 'ShePlays | Washington Spirit',
        view: <UWCLTeamM uwclteam={PSG} />
    }),
    '/uwcl/teams/m/mad': route({
        title: 'ShePlays | Washington Spirit',
        view: <UWCLTeamM uwclteam={MAD} />
    }),
    '/uwcl/teams/m/hac': route({
        title: 'ShePlays | Washington Spirit',
        view: <UWCLTeamM uwclteam={HAC} />
    }),
    '/wwc/m/teams/arg': route({
        title: 'ShePlays | ',
        view: <WWCTeamM wwcteam={ARG23} />
    }),
    '/wwc/m/teams/aus': route({
        title: 'ShePlays | ',
        view: <WWCTeamM wwcteam={AUS23} />
    }),
    '/wwc/m/teams/bra': route({
        title: 'ShePlays | ',
        view: <WWCTeamM wwcteam={BRA23} />
    }),
    '/wwc/m/teams/can': route({
        title: 'ShePlays | ',
        view: <WWCTeamM wwcteam={CAN23} />
    }),
    '/wwc/m/teams/chn': route({
        title: 'ShePlays | ',
        view: <WWCTeamM wwcteam={CHN23} />
    }),
    '/wwc/m/teams/col': route({
        title: 'ShePlays | ',
        view: <WWCTeamM wwcteam={COL23} />
    }),
    '/wwc/m/teams/cri': route({
        title: 'ShePlays | ',
        view: <WWCTeamM wwcteam={CRI23} />
    }),
    '/wwc/m/teams/dnk': route({
        title: 'ShePlays | ',
        view: <WWCTeamM wwcteam={DNK23} />
    }),
    '/wwc/m/teams/eng': route({
        title: 'ShePlays | ',
        view: <WWCTeamM wwcteam={ENG23} />
    }),
    '/wwc/m/teams/fra': route({
        title: 'ShePlays | ',
        view: <WWCTeamM wwcteam={FRA23} />
    }),
    '/wwc/m/teams/deu': route({
        title: 'ShePlays | ',
        view: <WWCTeamM wwcteam={DEU23} />
    }),
    '/wwc/m/teams/hti': route({
        title: 'ShePlays | ',
        view: <WWCTeamM wwcteam={HTI23} />
    }),
    '/wwc/m/teams/irl': route({
        title: 'ShePlays | ',
        view: <WWCTeamM wwcteam={IRL23} />
    }),
    '/wwc/m/teams/ita': route({
        title: 'ShePlays | ',
        view: <WWCTeamM wwcteam={ITA23} />
    }),
    '/wwc/m/teams/jam': route({
        title: 'ShePlays | ',
        view: <WWCTeamM wwcteam={JAM23} />
    }),
    '/wwc/m/teams/jpn': route({
        title: 'ShePlays | ',
        view: <WWCTeamM wwcteam={JPN23} />
    }),
    '/wwc/m/teams/mar': route({
        title: 'ShePlays | ',
        view: <WWCTeamM wwcteam={MAR23} />
    }),
    '/wwc/m/teams/nld': route({
        title: 'ShePlays | ',
        view: <WWCTeamM wwcteam={NLD23} />
    }),
    '/wwc/m/teams/nzl': route({
        title: 'ShePlays | ',
        view: <WWCTeamM wwcteam={NZL23} />
    }),
    '/wwc/m/teams/nga': route({
        title: 'ShePlays | ',
        view: <WWCTeamM wwcteam={NGA23} />
    }),
    '/wwc/m/teams/nor': route({
        title: 'ShePlays | ',
        view: <WWCTeamM wwcteam={NOR23} />
    }),
    '/wwc/m/teams/pan': route({
        title: 'ShePlays | ',
        view: <WWCTeamM wwcteam={PAN23} />
    }),
    '/wwc/m/teams/phl': route({
        title: 'ShePlays | ',
        view: <WWCTeamM wwcteam={PHL23} />
    }),
    '/wwc/m/teams/prt': route({
        title: 'ShePlays | ',
        view: <WWCTeamM wwcteam={PRT23} />
    }),
    '/wwc/m/teams/zaf': route({
        title: 'ShePlays | ',
        view: <WWCTeamM wwcteam={ZAF23} />
    }),
    '/wwc/m/teams/kor': route({
        title: 'ShePlays | ',
        view: <WWCTeamM wwcteam={KOR23} />
    }),
    '/wwc/m/teams/esp': route({
        title: 'ShePlays | ',
        view: <WWCTeamM wwcteam={ESP23} />
    }),
    '/wwc/m/teams/swe': route({
        title: 'ShePlays | ',
        view: <WWCTeamM wwcteam={SWE23} />
    }),
    '/wwc/m/teams/che': route({
        title: 'ShePlays | ',
        view: <WWCTeamM wwcteam={CHE23} />
    }),
    '/wwc/m/teams/usa': route({
        title: 'ShePlays | ',
        view: <WWCTeamM wwcteam={USA23} />
    }),
    '/wwc/m/teams/vnm': route({
        title: 'ShePlays | ',
        view: <WWCTeamM wwcteam={VNM23} />
    }),
    '/wwc/m/teams/zmb': route({
        title: 'ShePlays | ',
        view: <WWCTeamM wwcteam={ZMB23} />
    }),
    '/wwc/teams/arg': route({
        title: 'ShePlays | ',
        view: <WWCTeam wwcteam={ARG23} />
    }),
    '/wwc/teams/aus': route({
        title: 'ShePlays | ',
        view: <WWCTeam wwcteam={AUS23} />
    }),
    '/wwc/teams/bra': route({
        title: 'ShePlays | ',
        view: <WWCTeam wwcteam={BRA23} />
    }),
    '/wwc/teams/can': route({
        title: 'ShePlays | ',
        view: <WWCTeam wwcteam={CAN23} />
    }),
    '/wwc/teams/chn': route({
        title: 'ShePlays | ',
        view: <WWCTeam wwcteam={CHN23} />
    }),
    '/wwc/teams/col': route({
        title: 'ShePlays | ',
        view: <WWCTeam wwcteam={COL23} />
    }),
    '/wwc/teams/cri': route({
        title: 'ShePlays | ',
        view: <WWCTeam wwcteam={CRI23} />
    }),
    '/wwc/teams/dnk': route({
        title: 'ShePlays | ',
        view: <WWCTeam wwcteam={DNK23} />
    }),
    '/wwc/teams/eng': route({
        title: 'ShePlays | ',
        view: <WWCTeam wwcteam={ENG23} />
    }),
    '/wwc/teams/fra': route({
        title: 'ShePlays | ',
        view: <WWCTeam wwcteam={FRA23} />
    }),
    '/wwc/teams/deu': route({
        title: 'ShePlays | ',
        view: <WWCTeam wwcteam={DEU23} />
    }),
    '/wwc/teams/hti': route({
        title: 'ShePlays | ',
        view: <WWCTeam wwcteam={HTI23} />
    }),
    '/wwc/teams/irl': route({
        title: 'ShePlays | ',
        view: <WWCTeam wwcteam={IRL23} />
    }),
    '/wwc/teams/ita': route({
        title: 'ShePlays | ',
        view: <WWCTeam wwcteam={ITA23} />
    }),
    '/wwc/teams/jam': route({
        title: 'ShePlays | ',
        view: <WWCTeam wwcteam={JAM23} />
    }),
    '/wwc/teams/jpn': route({
        title: 'ShePlays | ',
        view: <WWCTeam wwcteam={JPN23} />
    }),
    '/wwc/teams/mar': route({
        title: 'ShePlays | ',
        view: <WWCTeam wwcteam={MAR23} />
    }),
    '/wwc/teams/nld': route({
        title: 'ShePlays | ',
        view: <WWCTeam wwcteam={NLD23} />
    }),
    '/wwc/teams/nzl': route({
        title: 'ShePlays | ',
        view: <WWCTeam wwcteam={NZL23} />
    }),
    '/wwc/teams/nga': route({
        title: 'ShePlays | ',
        view: <WWCTeam wwcteam={NGA23} />
    }),
    '/wwc/teams/nor': route({
        title: 'ShePlays | ',
        view: <WWCTeam wwcteam={NOR23} />
    }),
    '/wwc/teams/pan': route({
        title: 'ShePlays | ',
        view: <WWCTeam wwcteam={PAN23} />
    }),
    '/wwc/teams/phl': route({
        title: 'ShePlays | ',
        view: <WWCTeam wwcteam={PHL23} />
    }),
    '/wwc/teams/prt': route({
        title: 'ShePlays | ',
        view: <WWCTeam wwcteam={PRT23} />
    }),
    '/wwc/teams/zaf': route({
        title: 'ShePlays | ',
        view: <WWCTeam wwcteam={ZAF23} />
    }),
    '/wwc/teams/kor': route({
        title: 'ShePlays | ',
        view: <WWCTeam wwcteam={KOR23} />
    }),
    '/wwc/teams/esp': route({
        title: 'ShePlays | ',
        view: <WWCTeam wwcteam={ESP23} />
    }),
    '/wwc/teams/swe': route({
        title: 'ShePlays | ',
        view: <WWCTeam wwcteam={SWE23} />
    }),
    '/wwc/teams/che': route({
        title: 'ShePlays | ',
        view: <WWCTeam wwcteam={CHE23} />
    }),
    '/wwc/teams/usa': route({
        title: 'ShePlays | ',
        view: <WWCTeam wwcteam={USA23} />
    }),
    '/wwc/teams/vnm': route({
        title: 'ShePlays | ',
        view: <WWCTeam wwcteam={VNM23} />
    }),
    '/wwc/teams/zmb': route({
        title: 'ShePlays | ',
        view: <WWCTeam wwcteam={ZMB23} />
    }),
    '/euro/teams/aut': route({
        title: 'ShePlays | ',
        view: <EuroTeam euroteam={AUT} />
    }),
    '/euro/teams/bel': route({
        title: 'ShePlays | ',
        view: <EuroTeam euroteam={BEL} />
    }),
    '/euro/teams/dnk': route({
        title: 'ShePlays | ',
        view: <EuroTeam euroteam={DNK} />
    }),
    '/euro/teams/eng': route({
        title: 'ShePlays | ',
        view: <EuroTeam euroteam={ENG} />
    }),
    '/euro/teams/fin': route({
        title: 'ShePlays | ',
        view: <EuroTeam euroteam={FIN} />
    }),
    '/euro/teams/fra': route({
        title: 'ShePlays | ',
        view: <EuroTeam euroteam={FRA} />
    }),
    '/euro/teams/deu': route({
        title: 'ShePlays | ',
        view: <EuroTeam euroteam={DEU} />
    }),
    '/euro/teams/isl': route({
        title: 'ShePlays | ',
        view: <EuroTeam euroteam={ISL} />
    }),
    '/euro/teams/ita': route({
        title: 'ShePlays | ',
        view: <EuroTeam euroteam={ITA} />
    }),
    '/euro/teams/nld': route({
        title: 'ShePlays | ',
        view: <EuroTeam euroteam={NLD} />
    }),
    '/euro/teams/nir': route({
        title: 'ShePlays | ',
        view: <EuroTeam euroteam={NIR} />
    }),
    '/euro/teams/nor': route({
        title: 'ShePlays | ',
        view: <EuroTeam euroteam={NOR} />
    }),
    '/euro/teams/prt': route({
        title: 'ShePlays | ',
        view: <EuroTeam euroteam={PRT} />
    }),
    '/euro/teams/esp': route({
        title: 'ShePlays | ',
        view: <EuroTeam euroteam={ESP} />
    }),
    '/euro/teams/swe': route({
        title: 'ShePlays | ',
        view: <EuroTeam euroteam={SWE} />
    }),
    '/euro/teams/che': route({
        title: 'ShePlays | ',
        view: <EuroTeam euroteam={CHEW} />
    }),
    '/euro/m/teams/aut': route({
        title: 'ShePlays | ',
        view: <EuroTeamM euroteam={AUT} />
    }),
    '/euro/m/teams/bel': route({
        title: 'ShePlays | ',
        view: <EuroTeamM euroteam={BEL} />
    }),
    '/euro/m/teams/dnk': route({
        title: 'ShePlays | ',
        view: <EuroTeamM euroteam={DNK} />
    }),
    '/euro/m/teams/eng': route({
        title: 'ShePlays | ',
        view: <EuroTeamM euroteam={ENG} />
    }),
    '/euro/m/teams/fin': route({
        title: 'ShePlays | ',
        view: <EuroTeamM euroteam={FIN} />
    }),
    '/euro/m/teams/fra': route({
        title: 'ShePlays | ',
        view: <EuroTeamM euroteam={FRA} />
    }),
    '/euro/m/teams/deu': route({
        title: 'ShePlays | ',
        view: <EuroTeamM euroteam={DEU} />
    }),
    '/euro/m/teams/isl': route({
        title: 'ShePlays | ',
        view: <EuroTeamM euroteam={ISL} />
    }),
    '/euro/m/teams/ita': route({
        title: 'ShePlays | ',
        view: <EuroTeamM euroteam={ITA} />
    }),
    '/euro/m/teams/nld': route({
        title: 'ShePlays | ',
        view: <EuroTeamM euroteam={NLD} />
    }),
    '/euro/m/teams/nir': route({
        title: 'ShePlays | ',
        view: <EuroTeamM euroteam={NIR} />
    }),
    '/euro/m/teams/nor': route({
        title: 'ShePlays | ',
        view: <EuroTeamM euroteam={NOR} />
    }),
    '/euro/m/teams/prt': route({
        title: 'ShePlays | ',
        view: <EuroTeamM euroteam={PRT} />
    }),
    '/euro/m/teams/esp': route({
        title: 'ShePlays | ',
        view: <EuroTeamM euroteam={ESP} />
    }),
    '/euro/m/teams/swe': route({
        title: 'ShePlays | ',
        view: <EuroTeamM euroteam={SWE} />
    }),
    '/euro/m/teams/che': route({
        title: 'ShePlays | ',
        view: <EuroTeamM euroteam={CHEW} />
    }),
    '/aflw/fixtures': route({
        title: 'ShePlays | Fixtures & Results',
        view: AFLWFixtures
    }),
    '/aflw/m/fixtures': route({
        title: 'ShePlays | Fixtures & Results',
        view: AFLWFixturesM
    }),
    '/aflw/teams/ade': route({
        title: 'ShePlays | Adelaide',
        view: <AFLWTeam aflwteam={AdelaideCrows} />
    }),
    '/aflw/teams/bri': route({
        title: 'ShePlays | Brisbane',
        view: <AFLWTeam aflwteam={BrisbaneLions} />
    }),
    '/aflw/teams/car': route({
        title: 'ShePlays | Carlton',
        view: <AFLWTeam aflwteam={CarltonBlues} />
    }),
    '/aflw/teams/col': route({
        title: 'ShePlays | Collingwood',
        view: <AFLWTeam aflwteam={CollingwoodMagpies} />
    }),
    '/aflw/teams/fre': route({
        title: 'ShePlays | Fremantle',
        view: <AFLWTeam aflwteam={FremantleDockers} />
    }),
    '/aflw/teams/gee': route({
        title: 'ShePlays | Geelong',
        view: <AFLWTeam aflwteam={GeelongCats} />
    }),
    '/aflw/teams/gol': route({
        title: 'ShePlays | Gold Coast',
        view: <AFLWTeam aflwteam={GoldCoastSuns} />
    }),
    '/aflw/teams/gws': route({
        title: 'ShePlays | GWS',
        view: <AFLWTeam aflwteam={GWSGiants} />
    }),
    '/aflw/teams/mel': route({
        title: 'ShePlays | Melbourne',
        view: <AFLWTeam aflwteam={MelbourneDemons} />
    }),
    '/aflw/teams/nor': route({
        title: 'ShePlays | North Melbourne',
        view: <AFLWTeam aflwteam={NorthMelbourneKangaroos} />
    }),
    '/aflw/teams/ric': route({
        title: 'ShePlays | Richmond',
        view: <AFLWTeam aflwteam={RichmondTigers} />
    }),
    '/aflw/teams/stk': route({
        title: 'ShePlays | St Kilda',
        view: <AFLWTeam aflwteam={StKildaSaints} />
    }),
    '/aflw/teams/wce': route({
        title: 'ShePlays | West Coast',
        view: <AFLWTeam aflwteam={WestCoastEagles} />
    }),
    '/aflw/teams/wes': route({
        title: 'ShePlays | Bulldogs',
        view: <AFLWTeam aflwteam={WesternBulldogs} />
    }),
    '/aflw/teams/syd': route({
        title: 'ShePlays | Swans',
        view: <AFLWTeam aflwteam={SydneySwans} />
    }),
    '/aflw/teams/ess': route({
        title: 'ShePlays | Bombers',
        view: <AFLWTeam aflwteam={EssendonBombers} />
    }),
    '/aflw/teams/por': route({
        title: 'ShePlays | Port',
        view: <AFLWTeam aflwteam={PortAdelaide} />
    }),
    '/aflw/teams/haw': route({
        title: 'ShePlays | Hawks',
        view: <AFLWTeam aflwteam={HawthornHawks} />
    }),
    '/aflw/teams/m/ade': route({
        title: 'ShePlays | Adelaide',
        view: <AFLWTeamM aflwteam={AdelaideCrows} />
    }),
    '/aflw/teams/m/brl': route({
        title: 'ShePlays | Brisbane',
        view: <AFLWTeamM aflwteam={BrisbaneLions} />
    }),
    '/aflw/teams/m/car': route({
        title: 'ShePlays | Carlton',
        view: <AFLWTeamM aflwteam={CarltonBlues} />
    }),
    '/aflw/teams/m/col': route({
        title: 'ShePlays | Collingwood',
        view: <AFLWTeamM aflwteam={CollingwoodMagpies} />
    }),
    '/aflw/teams/m/fre': route({
        title: 'ShePlays | Fremantle',
        view: <AFLWTeamM aflwteam={FremantleDockers} />
    }),
    '/aflw/teams/m/gee': route({
        title: 'ShePlays | Geelong',
        view: <AFLWTeamM aflwteam={GeelongCats} />
    }),
    '/aflw/teams/m/gcs': route({
        title: 'ShePlays | Gold Coast',
        view: <AFLWTeamM aflwteam={GoldCoastSuns} />
    }),
    '/aflw/teams/m/gws': route({
        title: 'ShePlays | GWS',
        view: <AFLWTeamM aflwteam={GWSGiants} />
    }),
    '/aflw/teams/m/mel': route({
        title: 'ShePlays | Melbourne',
        view: <AFLWTeamM aflwteam={MelbourneDemons} />
    }),
    '/aflw/teams/m/nth': route({
        title: 'ShePlays | North Melbourne',
        view: <AFLWTeamM aflwteam={NorthMelbourneKangaroos} />
    }),
    '/aflw/teams/m/ric': route({
        title: 'ShePlays | Richmond',
        view: <AFLWTeamM aflwteam={RichmondTigers} />
    }),
    '/aflw/teams/m/stk': route({
        title: 'ShePlays | St Kilda',
        view: <AFLWTeamM aflwteam={StKildaSaints} />
    }),
    '/aflw/teams/m/wce': route({
        title: 'ShePlays | West Coast',
        view: <AFLWTeamM aflwteam={WestCoastEagles} />
    }),
    '/aflw/teams/m/wbd': route({
        title: 'ShePlays | Bulldogs',
        view: <AFLWTeamM aflwteam={WesternBulldogs} />
    }),
   '/aflw/teams/m/syd': route({
        title: 'ShePlays | Swans',
        view: <AFLWTeamM aflwteam={SydneySwans} />
    }),
    '/aflw/teams/m/ess': route({
        title: 'ShePlays | Bombers',
        view: <AFLWTeamM aflwteam={EssendonBombers} />
    }),
    '/aflw/teams/m/por': route({
        title: 'ShePlays | Port',
        view: <AFLWTeamM aflwteam={PortAdelaide} />
    }),
    '/aflw/teams/m/haw': route({
        title: 'ShePlays | Hawks',
        view: <AFLWTeamM aflwteam={HawthornHawks} />
    }),
    '/wl/teams/ade': route({
        title: 'ShePlays | Adelaide United',
        view: <WLTeam wlteam={ADE} />
    }),
    '/wl/teams/bri': route({
        title: 'ShePlays | Brisbane Roar',
        view: <WLTeam wlteam={BRI} />
    }),
    '/wl/teams/cbr': route({
        title: 'ShePlays | Canberra United',
        view: <WLTeam wlteam={CBR} />
    }),
    '/wl/teams/mcy': route({
        title: 'ShePlays | Melbourne City',
        view: <WLTeam wlteam={MCY} />
    }),
    '/wl/teams/mvc': route({
        title: 'ShePlays | Melbourne Victory',
        view: <WLTeam wlteam={MVC} />
    }),
    '/wl/teams/new': route({
        title: 'ShePlays | Newcastle United',
        view: <WLTeam wlteam={NEW} />
    }),
    '/wl/teams/per': route({
        title: 'ShePlays | Perth Glory',
        view: <WLTeam wlteam={PER} />
    }),
    '/wl/teams/syd': route({
        title: 'ShePlays | Sydney FC',
        view: <WLTeam wlteam={SYD} />
    }),
    '/wl/teams/wsw': route({
        title: 'ShePlays | Western Sydney Wanderers',
        view: <WLTeam wlteam={WSW} />
    }),
    '/wl/teams/wel': route({
        title: 'ShePlays | Wellington Phoenix',
        view: <WLTeam wlteam={WEL} />
    }),
    '/wl/teams/m/ade': route({
        title: 'ShePlays | Adelaide United',
        view: <WLTeamM wlteam={ADE} />
    }),
    '/wl/teams/m/bri': route({
        title: 'ShePlays | Brisbane Roar',
        view: <WLTeamM wlteam={BRI} />
    }),
    '/wl/teams/m/cbr': route({
        title: 'ShePlays | Canberra United',
        view: <WLTeamM wlteam={CBR} />
    }),
    '/wl/teams/m/mcy': route({
        title: 'ShePlays | Melbourne City',
        view: <WLTeamM wlteam={MCY} />
    }),
    '/wl/teams/m/mvc': route({
        title: 'ShePlays | Melbourne Victory',
        view: <WLTeamM wlteam={MVC} />
    }),
    '/wl/teams/m/new': route({
        title: 'ShePlays | Newcastle United',
        view: <WLTeamM wlteam={NEW} />
    }),
    '/wl/teams/m/per': route({
        title: 'ShePlays | Perth Glory',
        view: <WLTeamM wlteam={PER} />
    }),
    '/wl/teams/m/syd': route({
        title: 'ShePlays | Sydney FC',
        view: <WLTeamM wlteam={SYD} />
    }),
    '/wl/teams/m/wsw': route({
        title: 'ShePlays | Western Sydney Wanderers',
        view: <WLTeamM wlteam={WSW} />
    }),
    '/wl/teams/m/wel': route({
        title: 'ShePlays | Wellington Phoenix',
        view: <WLTeamM wlteam={WEL} />
    }),
    '/wl/2019/teams/ade': route({
        title: 'ShePlays | Adelaide United',
        view: <WLteams19 data={ADE19} />
    }),
    '/wl/2019/teams/bri': route({
        title: 'ShePlays | Brisbane Roar',
        view: <WLteams19 data={BRI19} />
    }),
    '/wl/2019/teams/cbr': route({
        title: 'ShePlays | Canberra United',
        view: <WLteams19 data={CBR19} />
    }),
    '/wl/2019/teams/mcy': route({
        title: 'ShePlays | Melbourne City',
        view: <WLteams19 data={MCY19} />
    }),
    '/wl/2019/teams/mvc': route({
        title: 'ShePlays | Melbourne Victory',
        view: <WLteams19 data={MVC19} />
    }),
    '/wl/2019/teams/per': route({
        title: 'ShePlays | Perth Glory',
        view: <WLteams19 data={PER19} />
    }),
    '/wl/2019/teams/syd': route({
        title: 'ShePlays | Sydney FC',
        view: <WLteams19 data={SYD19} />
    }),
    '/wl/2019/teams/wsw': route({
        title: 'ShePlays | Western Sydney Wanderers',
        view: <WLteams19 data={WSW19} />
    }),
    '/wl/2018/teams18/ade': route({
        title: 'ShePlays | Adelaide United',
        view: <WLTeam18 wlteam={ADE18} />
    }),
    '/wl/2018/teams18/bri': route({
        title: 'ShePlays | Brisbane Roar',
        view: <WLTeam18 wlteam={BRI18} />
    }),
    '/wl/2018/teams18/cbr': route({
        title: 'ShePlays | Canberra United',
        view: <WLTeam18 wlteam={CBR18} />
    }),
    '/wl/2018/teams18/mcy': route({
        title: 'ShePlays | Melbourne City',
        view: <WLTeam18 wlteam={MCY18} />
    }),
    '/wl/2018/teams18/mvc': route({
        title: 'ShePlays | Melbourne Victory',
        view: <WLTeam18 wlteam={MVC18} />
    }),
    '/wl/2018/teams18/new': route({
        title: 'ShePlays | Newcastle Jets',
        view: <WLTeam18 wlteam={NEW18} />
    }),
    '/wl/2018/teams18/per': route({
        title: 'ShePlays | Perth Glory',
        view: <WLTeam18 wlteam={PER18} />
    }),
    '/wl/2018/teams18/syd': route({
        title: 'ShePlays | Sydney FC',
        view: <WLTeam18 wlteam={SYD18} />
    }),
    '/wl/2018/teams18/wsw': route({
        title: 'ShePlays | Western Sydney Wanderers',
        view: <WLTeam18 wlteam={WSW18} />
    }),
    ..._.chain(WSLProfiles)
        .keyBy(({ name }) => `/wsl/players/${_.kebabCase(name)}`)
        .mapValues(profile => route({
            title: 'ShePlays | Plays',
            view: <WSLProfile wslprofile={profile} />
        }))
        .value(),
    ..._.chain(WSLProfiles)
        .keyBy(({ name }) => `/wsl/m/players/${_.kebabCase(name)}`)
        .mapValues(profile => route({
            title: 'ShePlays | Plays',
            view: <WSLProfileM wslprofile={profile} />
        }))
        .value(),
    ..._.chain(UWCLProfiles)
        .keyBy(({ name }) => `/uwcl/players/${_.kebabCase(name)}`)
        .mapValues(profile => route({
            title: 'ShePlays | Plays',
            view: <UWCLProfile uwclprofile={profile} />
        }))
        .value(),
    ..._.chain(UWCLProfiles)
        .keyBy(({ name }) => `/uwcl/m/players/${_.kebabCase(name)}`)
        .mapValues(profile => route({
            title: 'ShePlays | Plays',
            view: <UWCLProfileM uwclprofile={profile} />
        }))
        .value(),
    ..._.chain(NWSLProfiles)
        .keyBy(({ name }) => `/nwsl/players/${_.kebabCase(name)}`)
        .mapValues(profile => route({
            title: 'ShePlays | Plays',
            view: <NWSLProfile nwslprofile={profile} />
        }))
        .value(),
    ..._.chain(NWSLProfiles)
        .keyBy(({ name }) => `/nwsl/m/players/${_.kebabCase(name)}`)
        .mapValues(profile => route({
            title: 'ShePlays | Plays',
            view: <NWSLProfileM nwslprofile={profile} />
        }))
        .value(),
    ..._.chain(WLProfiles)
        .keyBy(({ name }) => `/wl/m/players/${_.kebabCase(name)}`)
        .mapValues(profile => route({
            title: 'ShePlays | Plays',
            view: <WLProfileM wlprofile={profile} />
        }))
        .value(),
    ..._.chain(WLProfiles)
        .keyBy(({ name }) => `/wl/players/${_.kebabCase(name)}`)
        .mapValues(profile => route({
            title: 'ShePlays | Plays',
            view: <WLProfile wlprofile={profile} />
        }))
        .value(),
})

ReactDOM.render(<Router routes={routes}>
    <>
        <Header />
        <Navbar />
        <View />
        <Footer />
    </>
</Router>, document.getElementById('root'));



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();



