export default [
  require("./playerProfiles/players/kenza-dali"),
  require("./playerProfiles/players/halle-houssein"),
  require("./playerProfiles/players/alejandra-bernabe"),
  require("./playerProfiles/players/lina-hurtig"),
  require("./playerProfiles/players/rosella-ayane"),
  require("./playerProfiles/players/laia-codina"),
  require("./playerProfiles/players/maz-pacheco"),
  require("./playerProfiles/players/aoife-mannion"),
  require("./playerProfiles/players/poppy-pritchard"),
  require("./playerProfiles/players/jenna-clark"),
  require("./playerProfiles/players/kinga-szemik"),
  require("./playerProfiles/players/janice-cayman"),
  require("./playerProfiles/players/amanda-nildén"),
  require("./playerProfiles/players/jordan-nobbs"),
  require("./playerProfiles/players/paula-tomás"),
  require("./playerProfiles/players/hannah-cain"),
  require("./playerProfiles/players/rebecca-rayner"),
  require("./playerProfiles/players/melissa-lawley"),
  require("./playerProfiles/players/clare-hunt"),
  require("./playerProfiles/players/leila-ouahabi"),
  require("./playerProfiles/players/eleanor-heeps"),
  require("./playerProfiles/players/dejana-stefanović"),
  require("./playerProfiles/players/madison-haley"),
  require("./playerProfiles/players/grace-fisk"),
  require("./playerProfiles/players/zara-shaw"),
  require("./playerProfiles/players/luana-bühler"),
  require("./playerProfiles/players/eve-perisset"),
  require("./playerProfiles/players/lucy-bronze"),
  require("./playerProfiles/players/noémie-mouchon"),
  require("./playerProfiles/players/lucy-parry"),
  require("./playerProfiles/players/ashleigh-neville"),
  require("./playerProfiles/players/johanna-rytting-kaneryd"),
  require("./playerProfiles/players/lucy-watson"),
  require("./playerProfiles/players/ashleigh-weerden"),
  require("./playerProfiles/players/mia-enderby"),
  require("./playerProfiles/players/aimee-everett"),
  require("./playerProfiles/players/drew-spence"),
  require("./playerProfiles/players/sophie-roman-haug"),
  require("./playerProfiles/players/vicky-losada"),
  require("./playerProfiles/players/lotte-wubben-moy"),
  require("./playerProfiles/players/molly-mae-sharpe"),
  require("./playerProfiles/players/adriana-leon"),
  require("./playerProfiles/players/lucy-hope"),
  require("./playerProfiles/players/alanna-kennedy"),
  require("./playerProfiles/players/lexi-potter"),
  require("./playerProfiles/players/oriane-jean-francois"),
  require("./playerProfiles/players/mille-gejl"),
  require("./playerProfiles/players/yuka-momiki"),
  require("./playerProfiles/players/megan-finnigan"),
  require("./playerProfiles/players/felicity-gibbons"),
  require("./playerProfiles/players/aisha-masaka"),
  require("./playerProfiles/players/becky-spencer"),
  require("./playerProfiles/players/dagný-brynjarsdóttir"),
  require("./playerProfiles/players/guro-reiten"),
  require("./playerProfiles/players/justine-vanhaevermaet"),
  require("./playerProfiles/players/jutta-rantala"),
  require("./playerProfiles/players/shelina-zadorsky"),
  require("./playerProfiles/players/rachael-laws"),
  require("./playerProfiles/players/emma-watson"),
  require("./playerProfiles/players/janina-leitzig"),
  require("./playerProfiles/players/katrine-veje"),
  require("./playerProfiles/players/hannah-poulter"),
  require("./playerProfiles/players/sari-kees"),
  require("./playerProfiles/players/libby-hart"),
  require("./playerProfiles/players/katie-startup"),
  require("./playerProfiles/players/guro-bergsvand"),
  require("./playerProfiles/players/laura-blindkilde-brown"),
  require("./playerProfiles/players/kathrine-møller-kühl"),
  require("./playerProfiles/players/amy-turner"),
  require("./playerProfiles/players/grace-clinton"),
  require("./playerProfiles/players/maisie-symonds"),
  require("./playerProfiles/players/steph-catley"),
  require("./playerProfiles/players/stina-blackstenius"),
  require("./playerProfiles/players/courtney-nevin"),
  require("./playerProfiles/players/ella-morris"),
  require("./playerProfiles/players/sjoeke-nüsken"),
  require("./playerProfiles/players/ella-toone"),
  require("./playerProfiles/players/zecira-musovic"),
  require("./playerProfiles/players/chasity-grant"),
  require("./playerProfiles/players/kirsty-hanson"),
  require("./playerProfiles/players/isabella-sibley"),
  require("./playerProfiles/players/katie-mccabe"),
  require("./playerProfiles/players/hayley-ladd"),
  require("./playerProfiles/players/lee-geum-min"),
  require("./playerProfiles/players/aniek-nouwen"),
  require("./playerProfiles/players/saori-takarada"),
  require("./playerProfiles/players/denny-draper"),
  require("./playerProfiles/players/aurora-galli"),
  require("./playerProfiles/players/ayaka-yamashita"),
  require("./playerProfiles/players/michelle-agyemang"),
  require("./playerProfiles/players/kadeisha-buchanan"),
  require("./playerProfiles/players/sophie-baggaley"),
  require("./playerProfiles/players/abby-clarke"),
  require("./playerProfiles/players/hayley-nolan"),
  require("./playerProfiles/players/kerstin-casparij"),
  require("./playerProfiles/players/annis-clara-wright"),
  require("./playerProfiles/players/sabrina-d'angelo"),
  require("./playerProfiles/players/anna-sandberg"),
  require("./playerProfiles/players/erin-cuthbert"),
  require("./playerProfiles/players/mary-fowler"),
  require("./playerProfiles/players/jelena-čanković"),
  require("./playerProfiles/players/lisa-naalsund"),
  require("./playerProfiles/players/asmita-ale"),
  require("./playerProfiles/players/sam-tierney"),
  require("./playerProfiles/players/macy-settle"),
  require("./playerProfiles/players/sophie-ingle"),
  require("./playerProfiles/players/niamh-charles"),
  require("./playerProfiles/players/lia-walti"),
  require("./playerProfiles/players/eveliina-summanen"),
  require("./playerProfiles/players/geyse-da-silva"),
  require("./playerProfiles/players/marisa-olislagers"),
  require("./playerProfiles/players/julie-thibaud"),
  require("./playerProfiles/players/emilia-pelgander"),
  require("./playerProfiles/players/agnes-beever-jones"),
  require("./playerProfiles/players/millie-bright"),
  require("./playerProfiles/players/charlotte-wardlaw"),
  require("./playerProfiles/players/cornelia-kapocs"),
  require("./playerProfiles/players/bruna-vilamala"),
  require("./playerProfiles/players/sandy-baltimore"),
  require("./playerProfiles/players/maya-le-tissier"),
  require("./playerProfiles/players/chantelle-swaby"),
  require("./playerProfiles/players/pauline-bremer"),
  require("./playerProfiles/players/wang-shuang"),
  require("./playerProfiles/players/dominique-janssen"),
  require("./playerProfiles/players/tara-o'hanlon"),
  require("./playerProfiles/players/teagan-micah"),
  require("./playerProfiles/players/jorelyn-carabali"),
  require("./playerProfiles/players/keira-barry"),
  require("./playerProfiles/players/katja-snoeijs"),
  require("./playerProfiles/players/rachel-corsie"),
  require("./playerProfiles/players/caitlin-foord"),
  require("./playerProfiles/players/emma-bissell"),
  require("./playerProfiles/players/matilda-vinberg"),
  require("./playerProfiles/players/jessica-naz"),
  require("./playerProfiles/players/marika-bergman-lundin"),
  require("./playerProfiles/players/missy-goodwin"),
  require("./playerProfiles/players/kit-graham"),
  require("./playerProfiles/players/khadija-shaw"),
  require("./playerProfiles/players/frida-maanum"),
  require("./playerProfiles/players/laia-aleixandri"),
  require("./playerProfiles/players/cerys-brown"),
  require("./playerProfiles/players/macey-nicholls"),
  require("./playerProfiles/players/leah-williamson"),
  require("./playerProfiles/players/martha-thomas"),
  require("./playerProfiles/players/toni-payne"),
  require("./playerProfiles/players/deanne-rose"),
  require("./playerProfiles/players/jayde-riviere"),
  require("./playerProfiles/players/lucy-staniforth"),
  require("./playerProfiles/players/naomi-layzell"),
  require("./playerProfiles/players/jasmine-matthews"),
  require("./playerProfiles/players/katie-robinson"),
  require("./playerProfiles/players/khiara-keating"),
  require("./playerProfiles/players/maika-hamano"),
  require("./playerProfiles/players/hinata-miyazawa"),
  require("./playerProfiles/players/simi-awujo"),
  require("./playerProfiles/players/riko-ueki"),
  require("./playerProfiles/players/amber-tysiak"),
  require("./playerProfiles/players/nikita-parris"),
  require("./playerProfiles/players/jorja-fox"),
  require("./playerProfiles/players/shannon-cooke"),
  require("./playerProfiles/players/emma-harries"),
  require("./playerProfiles/players/danielle-turner"),
  require("./playerProfiles/players/taylor-hinds"),
  require("./playerProfiles/players/safia-middleton-patel"),
  require("./playerProfiles/players/olivia-smith"),
  require("./playerProfiles/players/rachel-mclauchlan"),
  require("./playerProfiles/players/marit-auee"),
  require("./playerProfiles/players/maria-thorisdottir"),
  require("./playerProfiles/players/charlize-rule"),
  require("./playerProfiles/players/rachel-williams"),
  require("./playerProfiles/players/sophie-howard"),
  require("./playerProfiles/players/chloe-arthur"),
  require("./playerProfiles/players/manuela-zinsberger"),
  require("./playerProfiles/players/alyssa-aherne"),
  require("./playerProfiles/players/mayra-ramirez"),
  require("./playerProfiles/players/karen-holmgaard"),
  require("./playerProfiles/players/bethany-england"),
  require("./playerProfiles/players/aoba-fujino"),
  require("./playerProfiles/players/daphne-van-domselaar"),
  require("./playerProfiles/players/laura-coombs"),
  require("./playerProfiles/players/issy-hobson"),
  require("./playerProfiles/players/vivianne-miedema"),
  require("./playerProfiles/players/kiko-seike"),
  require("./playerProfiles/players/lena-petermann"),
  require("./playerProfiles/players/jess-simpson"),
  require("./playerProfiles/players/li-mengwen"),
  require("./playerProfiles/players/lucy-parker"),
  require("./playerProfiles/players/nathalie-bjorn"),
  require("./playerProfiles/players/sofie-lundgaard"),
  require("./playerProfiles/players/gabrielle-george"),
  require("./playerProfiles/players/araya-dennis"),
  require("./playerProfiles/players/kirsty-smith"),
  require("./playerProfiles/players/yana-daniels"),
  require("./playerProfiles/players/shanade-hopcroft"),
  require("./playerProfiles/players/hannah-blundell"),
  require("./playerProfiles/players/camila-sáez"),
  require("./playerProfiles/players/veatriki-sarri"),
  require("./playerProfiles/players/miri-taylor"),
  require("./playerProfiles/players/beth-mead"),
  require("./playerProfiles/players/martina-piemonte"),
  require("./playerProfiles/players/laura-wienroither"),
  require("./playerProfiles/players/rachel-daly"),
  require("./playerProfiles/players/anna-leat"),
  require("./playerProfiles/players/gemma-bonner"),
  require("./playerProfiles/players/karoline-olesen"),
  require("./playerProfiles/players/julia-bartel"),
  require("./playerProfiles/players/leanne-kiernan"),
  require("./playerProfiles/players/anna-patten"),
  require("./playerProfiles/players/fuka-nagano"),
  require("./playerProfiles/players/melvine-malard"),
  require("./playerProfiles/players/emily-orman"),
  require("./playerProfiles/players/honoka-hayashi"),
  require("./playerProfiles/players/naomi-williams"),
  require("./playerProfiles/players/marie-höbinger"),
  require("./playerProfiles/players/ebony-salmon"),
  require("./playerProfiles/players/shannon-o'brien"),
  require("./playerProfiles/players/gemma-evans"),
  require("./playerProfiles/players/lauren-james"),
  require("./playerProfiles/players/emily-ramsey"),
  require("./playerProfiles/players/millie-turner"),
  require("./playerProfiles/players/soraya-walsh"),
  require("./playerProfiles/players/evie-rabjohn"),
  require("./playerProfiles/players/molly-bartrip"),
  require("./playerProfiles/players/heather-payne"),
  require("./playerProfiles/players/courtney-brosnan"),
  require("./playerProfiles/players/leah-galton"),
  require("./playerProfiles/players/elise-stenevik"),
  require("./playerProfiles/players/indiah-paige-riley"),
  require("./playerProfiles/players/missy-bo-kearns"),
  require("./playerProfiles/players/georgia-mullett"),
  require("./playerProfiles/players/eve-annets"),
  require("./playerProfiles/players/sarah-mayling"),
  require("./playerProfiles/players/clare-wheeler"),
  require("./playerProfiles/players/kim-little"),
  require("./playerProfiles/players/lauren-hemp"),
  require("./playerProfiles/players/mariona-caldentey"),
  require("./playerProfiles/players/kristie-mewis"),
  require("./playerProfiles/players/fran-kirby"),
  require("./playerProfiles/players/wieke-kaptein"),
  require("./playerProfiles/players/emily-fox"),
  require("./playerProfiles/players/chloe-kelly"),
  require("./playerProfiles/players/lize-kop"),
  require("./playerProfiles/players/celin-bizet-iidusoy"),
  require("./playerProfiles/players/yui-hasegawa"),
  require("./playerProfiles/players/hannah-silcock"),
  require("./playerProfiles/players/viviane-asseyi"),
  require("./playerProfiles/players/jill-baijings"),
  require("./playerProfiles/players/anouk-denton"),
  require("./playerProfiles/players/brooke-aspin"),
  require("./playerProfiles/players/abbie-larkin"),
  require("./playerProfiles/players/jess-park"),
  require("./playerProfiles/players/phallon-tullis-joyce"),
  require("./playerProfiles/players/ashley-lawrence"),
  require("./playerProfiles/players/isibeal-atkinson"),
  require("./playerProfiles/players/risa-shimizu"),
  require("./playerProfiles/players/sara-holmgaard"),
  require("./playerProfiles/players/kenzie-weir"),
  require("./playerProfiles/players/elisabeth-terland"),
  require("./playerProfiles/players/annabel-blanchard"),
  require("./playerProfiles/players/megan-walsh"),
  require("./playerProfiles/players/melina-loeck"),
  require("./playerProfiles/players/rosa-kafaji"),
  require("./playerProfiles/players/charlotte-grant"),
  require("./playerProfiles/players/elise-hughes"),
  require("./playerProfiles/players/kyra-cooney-cross"),
  require("./playerProfiles/players/noelle-maritz"),
  require("./playerProfiles/players/inma-gabarro"),
  require("./playerProfiles/players/ruby-mace"),
  require("./playerProfiles/players/olga-ahtinen"),
  require("./playerProfiles/players/hannah-hampton"),
  require("./playerProfiles/players/katrina-gorry"),
  require("./playerProfiles/players/cj-bott"),
  require("./playerProfiles/players/rikke-madsen"),
  require("./playerProfiles/players/alex-greenwood"),
  require("./playerProfiles/players/alessia-russo"),
  require("./playerProfiles/players/shae-yanez"),
  require("./playerProfiles/players/ceri-holland"),
  require("./playerProfiles/players/niamh-fahey"),
  require("./playerProfiles/players/poppy-pattinson"),
]