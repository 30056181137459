import React from 'react';
import Flag from 'react-world-flags';
import "@devexpress/dx-react-grid";
import { CompetitionWrapperWSL } from '../../components/layout/CompetionWrapperWSL';
import { Input } from "reactstrap";
import { wsl } from '../../data/competitions/wsl';
import {
    SortingState,
    IntegratedSorting,
    PagingState,
    IntegratedPaging,
    SelectionState,
    DataTypeProvider,
    FilteringState,
    IntegratedFiltering
} from '@devexpress/dx-react-grid';
import {
    PagingPanel, Grid, Table, TableHeaderRow,
    TableFilterRow,
  TableSelection, } from '@devexpress/dx-react-grid-bootstrap4';

import data from "../../data/wsl/playerProfiles/allSeasons/allSeasonList"
import moment from 'moment';

const localDate = (dateString) => moment(dateString).format("Do MMMM, h:mm A")


const columns = [
    { title: 'Rank', name: 'rank' },
    { title: 'Player Name', name: 'name' },
    { title: 'Position', name: 'position' },
    /*{ title: 'Club', name: 'Club' },*/
    { title: 'Nationality', name: 'nationality' }, 
    { title: '19/20', name: 'points19'},
    { title: '20/21', name: 'points20'},
    { title: '21/22', name: 'points21'},
    { title: '22/23', name: 'points22'},
    {
        title: 'Total',
        name: 'totalPoints',
        getCellValue: t => t.totalPoints || 0
    }
]

const PositionPicker = ({ filter, onFilter }) => (
  <th>
    <Input
      type="select"
      value={filter ? filter.value : ""}
      onChange={e =>
        onFilter(e.target.value ? { value: e.target.value } : null)
      }
    >
      <option value="">Any position</option>
      <option>GK</option>
      <option>DF</option>
      <option>MF</option>
      <option>FW</option>
    </Input>
  </th>
);

const FilterCell = (props) => {
    const { column } = props;
    if (column.name === 'name') {
        return <TableFilterRow.Cell {...props} />;
    }
    if (column.name === 'nationality') {
        return <TableFilterRow.Cell {...props} />;
    }
    if (column.name === 'position') {
        return PositionPicker(props);
    }
    return <th />
};

const allPlayersWSL = () => <>
<CompetitionWrapperWSL competition={wsl}>
    <div className="container clearfix mt-32">
        <h1>Player Database - All Seasons</h1>
	<p>Browse all of the players who participated in the WSL during the last 2 seasons that we have ran 
	our ShePlays fantasy competition!  
	You can search for every player from the WSL in 2019/20, 2020/21, 2021/22, and 2022/23 (by name, nationality, or position) in our database below, 
	 where you can also see the total number of points each player has earned throughout each season.
    </p>
    </div>
    <div className="container">
       <h5 align="right" >- - - - - - - - - - - Fantasy Points - - - - - - - - - - -</h5> 
        <Grid rows={data.map((d, i) => ({...d, rank: i+1}))} columns={columns}>
            <PagingState
                defaultCurrentPage={0}
                pageSize={25}
            />

            <SortingState
                defaultSorting={[]}
            />
            <FilteringState defaultFilters={[]} />
            <IntegratedSorting />
            <IntegratedFiltering />
            <IntegratedPaging />
            <Table />
            <TableHeaderRow showSortingControls />
            <TableFilterRow cellComponent={FilterCell}/>
            <PagingPanel />
        </Grid>


    </div>
    <div className="container">
    <p></p>
    </div>
    </CompetitionWrapperWSL>
</>
export default allPlayersWSL
