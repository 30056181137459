import React, { useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap"; 
import { useAuth } from "../../util/withAuth";

const teams = [
  {code: 'aja', name: 'Ajax'},
  {code: 'fcb', name: 'Barcelona'},
  {code: 'bay', name: 'Bayern Munich'},
  {code: 'slb', name: 'Benfica'},
  {code: 'skb', name: 'Brann'},
  {code: 'che', name: 'Chelsea'},
  {code: 'sge', name: 'Eintracht Frankfurt'},
  {code: 'hac', name: 'Häcken'},
  {code: 'lyo', name: 'Lyon'},
  {code: 'pfc', name: 'Paris FC'},
  {code: 'psg', name: 'Paris Saint-Germain'},
  {code: 'mad', name: 'Real Madrid'},
  {code: 'rom', name: 'Roma'},
  {code: 'rbk', name: 'Rosengård'},
  {code: 'slp', name: 'Slavia Prague'},
  {code: 'skn', name: 'St. Pölten'}
]

export const CompetitionWrapperUWCL = ({ competition, children }) => {
  const [teamDropdown, setTeamDropdown] = useState(false, []); 
  const [newsDropdown, setNewsDropdown] = useState(false, []);
  const [playerDropdown, setPlayerDropdown] = useState(false, []);
  const [oldDropdown, setoldDropdown] = useState(false, []);
  const currentUser = useAuth()

  return (
    <div className={`competition-${competition.shortName}`}>
      <nav className="navbar navbar-expand-lg navbar-custom navbar-uwcl">
        <div className="container">
          <ul className="navbar-nav mr-5">
            <li className="nav-item">
              <a
                className="nav-link"
                href={`/${competition.shortName}/how-to-play`}
              >
                Rules &amp; Scoring
              </a>
            </li>
           <li className="nav-item">
              <a
                className="nav-link"
                href={`/${competition.shortName}/fixtures`}
              >
              Fixtures & Results
              </a>
            </li> 
            <li className="nav-item">
            <Dropdown
              isOpen={teamDropdown}
              toggle={() => setTeamDropdown(!teamDropdown)}
            >
              <DropdownToggle className="nav-link" caret>
                Teams
              </DropdownToggle>
              <DropdownMenu style={{ position: 'absolute', zIndex: '3000' }}>
                {teams.map(team => (
                  <DropdownItem
                    key={team.code}
                    href={`/${competition.shortName}/teams/${team.code ||
                      team.code}`.toLowerCase()}
                  >
                    {team.name}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
            </li>
            <li className="nav-item" >
              <Dropdown
                isOpen={playerDropdown}
                toggle={() => setPlayerDropdown(!playerDropdown)}
              >
                <DropdownToggle className="nav-link" caret>
                  Players
                </DropdownToggle>
                <DropdownMenu style={{ position: 'absolute', zIndex: '3000' }}>
                  <DropdownItem href="/uwcl/database">Player Database</DropdownItem>
	          <DropdownItem href="/uwcl/stats">Stats Center</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </li>
	    <li className="nav-item">
             <a
              className="nav-link"
              href="/uwcl/leaderboard"
             >
              Leaderboard
             </a>
            </li>
	    <li className="nav-item">
	     <a 
	      className="nav-link"
	      href="https://blog.sheplays.com.au/"
	     >
	      Latest News
	     </a>
	    </li>
            {currentUser &&
            <li className="nav-item">
              <a className="nav-link" href={`/${competition.shortName}/my-team`}>
                My Team
              </a>
            </li>
            }
          </ul>
        </div>
      </nav>
      {children}
    </div>
  );
};
