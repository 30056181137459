import React from 'react';
import DynamicMatch from '../../components/matches/Match';
import { CompetitionWrapperUWCL } from '../../components/layout/CompetionWrapperUWCL';
import { uwcl } from '../../data/competitions/uwcl';

const teams = [
  {
    "code": "AJA",
    "name": "Ajax"
  },
  {
    "code": "FCB",
    "name": "Barcelona"
  },
  {
    "code": "BAY",
    "name": "Bayern Munich"
  },
  {
    "code": "SLB",
    "name": "Benfica"
  },
  {
    "code": "SKB",
    "name": "Brann"
  },
  {
    "code": "CHE",
    "name": "Chelsea"
  },
  {
    "code": "SGE",
    "name": "Eintracht Frankfurt"
  },
  {
    "code": "HAC",
    "name": "Häcken"
  },
  {
    "code": "LYO",
    "name": "Lyon"
  },
  {
    "code": "PFC",
    "name": "Paris FC"
  },
  {
    "code": "PSG",
    "name": "Paris Saint-Germain"
  },
  {
    "code": "MAD",
    "name": "Real Madrid"
  },
  {
    "code": "ROM",
    "name": "Roma"
  },
  {
    "code": "RBK",
    "name": "Rosengård"
  },
  {
    "code": "SLP",
    "name": "Slavia Prague"
  },
  {
    "code": "SKN",
    "name": "St. Pölten"
  }
]

const UWCLMatch = ({route}) => {
    return (<CompetitionWrapperUWCL competition={uwcl}>
        <DynamicMatch {...{route, teams}} comp="uwcl" />
    </CompetitionWrapperUWCL>)
}
export default UWCLMatch;
