import React from "react";
import _ from "lodash";
import MyLeagues from "../leagues/MyLeagues";


const MyUWCLLeagues = ({ route }) => {
  return (<>
    <div className="container my-3">
        <h1>UWCL Mini Leagues</h1>
        <p>This is where you will be able to manage your mini-leagues, to play against your
          friends, family, and work colleagues! Once you have selected your fantasy team, you may
					join existing leagues or create your own!
      </p>
      
	  <MyLeagues route={route} competition="uwcl" />
    
    </div>
<div className="container">
	  <p>
	  Want to find public mini-leagues to test your fantasy UWCL team against?
Or want to learn about some of the organisations that are doing great
work to help grow women's football in europe? Then check out the open
mini-leagues from some of our friends below!
	  </p>

      <table className="table table-striped">
        <thead className="thead-dark">
<tr>
<th scope="col"></th>
<th scope="col">Name</th>
<th scope="col">Code</th>
<th scope="col"></th>
</tr>
</thead>

<tbody>
<tr>
<td><img hspace="20" src="/img/logo-round.png" width="40"/></td>
<td>ShePlays</td>
<td>sheplays</td>
<td>Compete against the ShePlays admin team</td>
</tr>
<tr>
<td><img hspace="20" src="/img/gotb2.jpg" width="40"/></td>
<td>Girls on the Ball</td>
<td>GOTB</td>
<td>Online platform promoting UK + Irish women's football.</td>
</tr>
<tr>
<td><img hspace="20" src="/img/tgtbsmall.jpg" width="40"/></td>
<td>Two Girls Talk Ball</td>
<td>TGTB</td>
<td>The alternative take on Women's Football // podcast hosted by @cfrench09 and @connor_tamsin!</td>
</tr>
<tr>
<td><img hspace="20" src="/img/EFF_logo.png" width="40"/></td>
<td>Era Fútbol Femenino UWCL</td>
<td>EFF</td>
<td>Medio de comunicación dedicado al fútbol femenino español e internacional // News dedicated to Spanish and International Women's Football</td>
</tr>
<tr>
<td><img hspace="20" src="/img/stbsmall.jpg" width="40"/></td>
<td>She Talks Ball</td>
<td>STB</td>
<td>Women's sports content, coverage & conversation | run by @radhalathgupta.</td>
</tr>
<tr>
<td><img hspace="20" src="/img/wososhow.png" width="40"/></td>
<td>The Women's Soccer Show</td>
<td>thewososhow</td>
<td>Official Mini-League of award-winning Women's Football Podcast 'The Women's Soccer Show'</td>
</tr>
<tr>
<td><img hspace="20" src="/img/CWSG.jpg" width="40"/></td>
<td>Chelsea Women Supporters Group</td>
<td>CWSG</td>
<td>Home of Chelsea Women Supporters Group</td>
</tr>
<tr>
<td><img hspace="20" src="/img/RMFI.png" width="40"/></td>
<td>Real Madrid Femenino Info</td>
<td>RMFI</td>
<td>@madridfeminfo, Pagina enfocada a la información del Real Madrid Femenino en español</td>
</tr>
</tbody>
</table> 
</div>

 </> );
};




export default MyUWCLLeagues;


