import React from 'react';
import moment from'moment';
import Flag from 'react-world-flags';
import { CompetitionWrapperUWCL } from "../../components/layout/CompetionWrapperUWCL";
import BottomNavBar from '../../components/layout/MCompetionWrapperUWCL.jsx';
import { uwcl } from "../../data/competitions/uwcl";

const localDate = (dateString) => moment(dateString).format("Do MMMM, h:mm A")

const teams = [
  {code: 'aja', name: 'Ajax'},
  {code: 'fcb', name: 'Barcelona'},
  {code: 'bay', name: 'Bayern Munich'},
  {code: 'slb', name: 'Benfica'},
  {code: 'skb', name: 'Brann'},
  {code: 'che', name: 'Chelsea'},
  {code: 'sge', name: 'Eintracht Frankfurt'},
  {code: 'hac', name: 'Häcken'},
  {code: 'lyo', name: 'Lyon'},
  {code: 'pfc', name: 'Paris FC'},
  {code: 'psg', name: 'Paris Saint-Germain'},
  {code: 'mad', name: 'Real Madrid'},
  {code: 'rom', name: 'Roma'},
  {code: 'rbk', name: 'Rosengård'},
  {code: 'slp', name: 'Slavia Prague'},
  {code: 'skn', name: 'St. Pölten'}
];

const Mteams = () => (<>
<head>
<meta name="viewport" content="width=device-width, initial-scale=1"></meta>
</head>
    <div className="container">
	{teams.map(team=> (
	<a
                href={`/uwcl/teams/m/${team.code}`}
                className="btn btn-teams btn-l rounded-pill mt-1" style={{alignSelf:'stretch'}}
              >
		{team.name}<img classname="rounded mx-auto d-block mb-3" align="right" height={30} src={`/img/uwcl/badges/${team.code.toUpperCase()}.png`}/>
              </a>
	))}
        </div>

<BottomNavBar/>
	
</>)

export default Mteams;
