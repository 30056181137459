export default [
  require("./playerProfiles/players/pauleta"),
  require("./playerProfiles/players/nadine-noordam"),
  require("./playerProfiles/players/svava-guðmundsdóttir"),
  require("./playerProfiles/players/sam-kerr"),
  require("./playerProfiles/players/aivi-luik"),
  require("./playerProfiles/players/olivia-holdt"),
  require("./playerProfiles/players/aitana-bonmatí"),
  require("./playerProfiles/players/aldiana-amuchie"),
  require("./playerProfiles/players/matilda-nildén"),
  require("./playerProfiles/players/larissa-crummer"),
  require("./playerProfiles/players/asisat-oshoala"),
  require("./playerProfiles/players/inès-marques"),
  require("./playerProfiles/players/teresa-abelleira"),
  require("./playerProfiles/players/jonna-van-de-velde"),
  require("./playerProfiles/players/rosa-kafaji"),
  require("./playerProfiles/players/tereza-szewieczková"),
  require("./playerProfiles/players/stéphanie-öhrström"),
  require("./playerProfiles/players/isabelle-meyer"),
  require("./playerProfiles/players/jovana-damnjanović"),
  require("./playerProfiles/players/patri-guijarro"),
  require("./playerProfiles/players/jelena-čanković"),
  require("./playerProfiles/players/alika-keene"),
  require("./playerProfiles/players/anna-anvegård"),
  require("./playerProfiles/players/constance-picaud"),
  require("./playerProfiles/players/carolin-simon"),
  require("./playerProfiles/players/valentina-mädl"),
  require("./playerProfiles/players/rita-schumacher"),
  require("./playerProfiles/players/laura-benkarth"),
  require("./playerProfiles/players/jade-le-guilly"),
  require("./playerProfiles/players/aurora-mikalsen"),
  require("./playerProfiles/players/danique-noordman"),
  require("./playerProfiles/players/kysha-sylla"),
  require("./playerProfiles/players/carla-carrillo"),
  require("./playerProfiles/players/zecira-musovic"),
  require("./playerProfiles/players/clare-hunt"),
  require("./playerProfiles/players/roos-van-der-veen"),
  require("./playerProfiles/players/ann-katrin-berger"),
  require("./playerProfiles/players/lena-pauels"),
  require("./playerProfiles/players/keira-walsh"),
  require("./playerProfiles/players/virginia-kirchberger"),
  require("./playerProfiles/players/marta-cintra"),
  require("./playerProfiles/players/hannah-hampton"),
  require("./playerProfiles/players/josefine-rybrink"),
  require("./playerProfiles/players/giulia-gwinn"),
  require("./playerProfiles/players/eva-gaetino"),
  require("./playerProfiles/players/aisha-masaka"),
  require("./playerProfiles/players/aneta-surová"),
  require("./playerProfiles/players/julie-swierot"),
  require("./playerProfiles/players/pia-sophie-wolter"),
  require("./playerProfiles/players/christy-ucheibe"),
  require("./playerProfiles/players/marta-salvador"),
  require("./playerProfiles/players/ana-vitória"),
  require("./playerProfiles/players/sophia-kleinherne"),
  require("./playerProfiles/players/tabitha-chawinga"),
  require("./playerProfiles/players/remina-chiba"),
  require("./playerProfiles/players/cara-bösl"),
  require("./playerProfiles/players/mateja-zver"),
  require("./playerProfiles/players/agnes-beever-jones"),
  require("./playerProfiles/players/linda-sembrant"),
  require("./playerProfiles/players/kristyna-ružičková"),
  require("./playerProfiles/players/sjoeke-nusken"),
  require("./playerProfiles/players/alizée-flagellat"),
  require("./playerProfiles/players/lúcia-alves"),
  require("./playerProfiles/players/catarina-macario"),
  require("./playerProfiles/players/hawa-sangaré"),
  require("./playerProfiles/players/ana-seiça"),
  require("./playerProfiles/players/ada-hegerberg"),
  require("./playerProfiles/players/franziska-kett"),
  require("./playerProfiles/players/samantha-kerr"),
  require("./playerProfiles/players/petra-divišová"),
  require("./playerProfiles/players/mariona-caldentey"),
  require("./playerProfiles/players/sandra-paños"),
  require("./playerProfiles/players/elena-linari"),
  require("./playerProfiles/players/laura-freigang"),
  require("./playerProfiles/players/quinty-sabajo"),
  require("./playerProfiles/players/nikita-tromp"),
  require("./playerProfiles/players/mariella-falkensteiner"),
  require("./playerProfiles/players/laura-feiersinger"),
  require("./playerProfiles/players/glódís-viggósdóttir"),
  require("./playerProfiles/players/ria-öling"),
  require("./playerProfiles/players/signe-gaupset"),
  require("./playerProfiles/players/viola-calligaris"),
  require("./playerProfiles/players/lucía-corrales"),
  require("./playerProfiles/players/oihane-valdezate"),
  require("./playerProfiles/players/sophie-nachtigall"),
  require("./playerProfiles/players/sydney-lohmann"),
  require("./playerProfiles/players/eve-perisset"),
  require("./playerProfiles/players/dzenifer-marozsán"),
  require("./playerProfiles/players/lucia-di-guglielmo"),
  require("./playerProfiles/players/tanja-pawollek"),
  require("./playerProfiles/players/catarina-amado"),
  require("./playerProfiles/players/felicia-schröder"),
  require("./playerProfiles/players/lina-magull"),
  require("./playerProfiles/players/athenea-del-castillo"),
  require("./playerProfiles/players/sarah-hunter"),
  require("./playerProfiles/players/bruna-vilamala"),
  require("./playerProfiles/players/annamaria-serturini"),
  require("./playerProfiles/players/delphine-cascarino"),
  require("./playerProfiles/players/mathilde-bourdieu"),
  require("./playerProfiles/players/hannah-johann"),
  require("./playerProfiles/players/hanna-andersson"),
  require("./playerProfiles/players/oihane-hernández"),
  require("./playerProfiles/players/mikaela-stojanovska"),
  require("./playerProfiles/players/katariina-kosola"),
  require("./playerProfiles/players/claudia-zornoza"),
  require("./playerProfiles/players/marie-antoinette-katoto"),
  require("./playerProfiles/players/nicole-anyomi"),
  require("./playerProfiles/players/teninsoun-sissoko"),
  require("./playerProfiles/players/olivie-lukášová"),
  require("./playerProfiles/players/océane-toussaint"),
  require("./playerProfiles/players/elma-junttila-nelhage"),
  require("./playerProfiles/players/anna-aehling"),
  require("./playerProfiles/players/gaëtane-thiney"),
  require("./playerProfiles/players/tuva-hansen"),
  require("./playerProfiles/players/ingrid-stenevik"),
  require("./playerProfiles/players/alice-marques"),
  require("./playerProfiles/players/ella-touon"),
  require("./playerProfiles/players/romée-leuchter"),
  require("./playerProfiles/players/ashleigh-weerden"),
  require("./playerProfiles/players/damaris-egurrola"),
  require("./playerProfiles/players/lucy-bronze"),
  require("./playerProfiles/players/inès-benyahia"),
  require("./playerProfiles/players/bea-sprung"),
  require("./playerProfiles/players/ivana-andrés"),
  require("./playerProfiles/players/letícia-almeida"),
  require("./playerProfiles/players/marjolen-nekesa"),
  require("./playerProfiles/players/johanna-rytting-kaneryd"),
  require("./playerProfiles/players/weronika-zawistowska"),
  require("./playerProfiles/players/anna-wellmann"),
  require("./playerProfiles/players/nanne-ruuskanen"),
  require("./playerProfiles/players/wendie-renard"),
  require("./playerProfiles/players/nadine-riesen"),
  require("./playerProfiles/players/beatriz-nogueira"),
  require("./playerProfiles/players/kika-nazareth"),
  require("./playerProfiles/players/jessie-fleming"),
  require("./playerProfiles/players/zara-kramžar"),
  require("./playerProfiles/players/rakel-engesvik"),
  require("./playerProfiles/players/athinna-lundgren"),
  require("./playerProfiles/players/lara-martins"),
  require("./playerProfiles/players/sara-doorsoun"),
  require("./playerProfiles/players/rebecca-knaak"),
  require("./playerProfiles/players/célina-ould-hocine"),
  require("./playerProfiles/players/anna-gasper"),
  require("./playerProfiles/players/dilara-açıkgöz"),
  require("./playerProfiles/players/stina-johannes"),
  require("./playerProfiles/players/maite-oroz"),
  require("./playerProfiles/players/melanie-brunnthaler"),
  require("./playerProfiles/players/kay-lee-de-sanders"),
  require("./playerProfiles/players/marit-bratberg-lund"),
  require("./playerProfiles/players/anna-johanning"),
  require("./playerProfiles/players/joanna-tynnilä"),
  require("./playerProfiles/players/sarah-zadrazil"),
  require("./playerProfiles/players/isabelle-hoekstra"),
  require("./playerProfiles/players/sofie-bredgaard"),
  require("./playerProfiles/players/selma-bacha"),
  require("./playerProfiles/players/ona-batlle"),
  require("./playerProfiles/players/kessya-bussy"),
  require("./playerProfiles/players/julie-soyer"),
  require("./playerProfiles/players/lea-schüller"),
  require("./playerProfiles/players/olivia-schough"),
  require("./playerProfiles/players/tilde-björklund"),
  require("./playerProfiles/players/rosa-van-gool"),
  require("./playerProfiles/players/ariana-arias"),
  require("./playerProfiles/players/sara-däbritz"),
  require("./playerProfiles/players/maren-mjelde"),
  require("./playerProfiles/players/evelyne-viens"),
  require("./playerProfiles/players/maeline-mendy"),
  require("./playerProfiles/players/jéssica-silva"),
  require("./playerProfiles/players/aniek-nouwen"),
  require("./playerProfiles/players/linda-caicedo"),
  require("./playerProfiles/players/guro-reiten"),
  require("./playerProfiles/players/anna-csiki"),
  require("./playerProfiles/players/eugénie-le-sommer"),
  require("./playerProfiles/players/aïssatou-tounkara"),
  require("./playerProfiles/players/berglind-thorvaldsdottir"),
  require("./playerProfiles/players/verena-hanshaw"),
  require("./playerProfiles/players/emilia-larsson"),
  require("./playerProfiles/players/andrine-hegerberg"),
  require("./playerProfiles/players/liana-joseph"),
  require("./playerProfiles/players/louise-fleury"),
  require("./playerProfiles/players/esmee-brugts"),
  require("./playerProfiles/players/matilde-silva"),
  require("./playerProfiles/players/cata-coll"),
  require("./playerProfiles/players/freja-olofsson"),
  require("./playerProfiles/players/anna-sandberg"),
  require("./playerProfiles/players/katerina-vithová"),
  require("./playerProfiles/players/stine-larsen"),
  require("./playerProfiles/players/marika-bergman-lundin"),
  require("./playerProfiles/players/vicki-becho"),
  require("./playerProfiles/players/judit-pujols"),
  require("./playerProfiles/players/halimatu-ayinde"),
  require("./playerProfiles/players/lauren-james"),
  require("./playerProfiles/players/natasha-anasi"),
  require("./playerProfiles/players/lucie-bendová"),
  require("./playerProfiles/players/dionne-van-der-wal"),
  require("./playerProfiles/players/isa-kardinaal"),
  require("./playerProfiles/players/tereza-krejčiríková"),
  require("./playerProfiles/players/justine-kielland"),
  require("./playerProfiles/players/molly-mclaughlin"),
  require("./playerProfiles/players/molly-johansson"),
  require("./playerProfiles/players/júlia-bartel"),
  require("./playerProfiles/players/korbin-albert"),
  require("./playerProfiles/players/margaux-le-mouël"),
  require("./playerProfiles/players/fridolina-rolfö"),
  require("./playerProfiles/players/élisa-de-almeida"),
  require("./playerProfiles/players/lisanne-gräwe"),
  require("./playerProfiles/players/letícia-santos"),
  require("./playerProfiles/players/mylene-chavas"),
  require("./playerProfiles/players/millie-bright"),
  require("./playerProfiles/players/jóhanna-sørensen"),
  require("./playerProfiles/players/monica-jusu-bah"),
  require("./playerProfiles/players/nicole-bryscejnovà"),
  require("./playerProfiles/players/andreia-norton"),
  require("./playerProfiles/players/alara-şehitler"),
  require("./playerProfiles/players/griedge-mbock-bathy"),
  require("./playerProfiles/players/perle-morroni"),
  require("./playerProfiles/players/lieke-martens"),
  require("./playerProfiles/players/kathellen"),
  require("./playerProfiles/players/nora-eide-lie"),
  require("./playerProfiles/players/kaja-korošec"),
  require("./playerProfiles/players/moeka-minami"),
  require("./playerProfiles/players/julia-tabotta"),
  require("./playerProfiles/players/jess-carter"),
  require("./playerProfiles/players/milicia-keijzer"),
  require("./playerProfiles/players/klara-bühl"),
  require("./playerProfiles/players/elin-rubensson"),
  require("./playerProfiles/players/disa-hellwig"),
  require("./playerProfiles/players/danique-tolhoek"),
  require("./playerProfiles/players/wassa-sangaré"),
  require("./playerProfiles/players/tereza-fuchsovà"),
  require("./playerProfiles/players/daphne-corboz"),
  require("./playerProfiles/players/emilie-haavi"),
  require("./playerProfiles/players/mayra-ramirez"),
  require("./playerProfiles/players/jill-baijings"),
  require("./playerProfiles/players/carolina-vilão"),
  require("./playerProfiles/players/chasity-grant"),
  require("./playerProfiles/players/ruby-grant"),
  require("./playerProfiles/players/tiny-hoekstra"),
  require("./playerProfiles/players/melissa-abiral"),
  require("./playerProfiles/players/player-not-available"),
  require("./playerProfiles/players/cecilie-redisch-kvamme"),
  require("./playerProfiles/players/maria-luisa-grohs"),
  require("./playerProfiles/players/laurina-fazer"),
  require("./playerProfiles/players/mai-kadowaki"),
  require("./playerProfiles/players/lucie-kroupová"),
  require("./playerProfiles/players/alexandra-bíróová"),
  require("./playerProfiles/players/soraya-verhoeve"),
  require("./playerProfiles/players/andreia-faria"),
  require("./playerProfiles/players/carina-schlüter"),
  require("./playerProfiles/players/lotte-keutelaar"),
  require("./playerProfiles/players/rocío-gálvez"),
  require("./playerProfiles/players/amelie-kandlhofer"),
  require("./playerProfiles/players/théa-greboval"),
  require("./playerProfiles/players/sakina-karchaoui"),
  require("./playerProfiles/players/simona-necidová"),
  require("./playerProfiles/players/sara-felicia-schröder"),
  require("./playerProfiles/players/mille-aune"),
  require("./playerProfiles/players/rute-costa"),
  require("./playerProfiles/players/karoline-haugland"),
  require("./playerProfiles/players/caroline-møller"),
  require("./playerProfiles/players/giada-greggi"),
  require("./playerProfiles/players/adéla-zomberová"),
  require("./playerProfiles/players/tomine-svendheim"),
  require("./playerProfiles/players/valéria-cantuário"),
  require("./playerProfiles/players/tainara"),
  require("./playerProfiles/players/hayley-raso"),
  require("./playerProfiles/players/haleigh-stackpole"),
  require("./playerProfiles/players/regina-van-eijk"),
  require("./playerProfiles/players/emma-jansson"),
  require("./playerProfiles/players/chimaka-nnadozie"),
  require("./playerProfiles/players/fiona-brown"),
  require("./playerProfiles/players/erin-nayler"),
  require("./playerProfiles/players/erin-cuthbert"),
  require("./playerProfiles/players/diana-bartovičová"),
  require("./playerProfiles/players/kadidiatou-diani"),
  require("./playerProfiles/players/mia-persson"),
  require("./playerProfiles/players/manssita-traoré"),
  require("./playerProfiles/players/manuela-giugliano"),
  require("./playerProfiles/players/tinja-riikka-korpela"),
  require("./playerProfiles/players/pna"),
  require("./playerProfiles/players/hanna-wijk"),
  require("./playerProfiles/players/barbara-dunst"),
  require("./playerProfiles/players/nathalie-björn"),
  require("./playerProfiles/players/grace-geyoro"),
  require("./playerProfiles/players/alsu-abdullina"),
  require("./playerProfiles/players/clàudia-pina"),
  require("./playerProfiles/players/benedetta-glionna"),
  require("./playerProfiles/players/lou-bogaert"),
  require("./playerProfiles/players/alice-sombath"),
  require("./playerProfiles/players/emma-berglund"),
  require("./playerProfiles/players/katarzyna-kiedrzynek"),
  require("./playerProfiles/players/katharina-naschenweng"),
  require("./playerProfiles/players/lois-niënhuis"),
  require("./playerProfiles/players/kadeisha-buchanan"),
  require("./playerProfiles/players/maría-león"),
  require("./playerProfiles/players/océane-hurté"),
  require("./playerProfiles/players/sandy-baltimore"),
  require("./playerProfiles/players/alexandra-larsson"),
  require("./playerProfiles/players/alexia-putellas"),
  require("./playerProfiles/players/paige-almendariz"),
  require("./playerProfiles/players/adina-hamidovic"),
  require("./playerProfiles/players/sarah-mattner"),
  require("./playerProfiles/players/bárbara-latorre"),
  require("./playerProfiles/players/daliyah-de-klonia"),
  require("./playerProfiles/players/ashley-lawrence"),
  require("./playerProfiles/players/sandie-toletti"),
  require("./playerProfiles/players/sara-ritter"),
  require("./playerProfiles/players/mia-fishel"),
  require("./playerProfiles/players/misa-rodríguez"),
  require("./playerProfiles/players/sherida-spitse"),
  require("./playerProfiles/players/sandra-stavenes"),
  require("./playerProfiles/players/andrea-falcón"),
  require("./playerProfiles/players/ana-guzmán"),
  require("./playerProfiles/players/kenti-robles"),
  require("./playerProfiles/players/carole-costa"),
  require("./playerProfiles/players/ramona-bachmann"),
  require("./playerProfiles/players/caroline-seger"),
  require("./playerProfiles/players/sofie-svava"),
  require("./playerProfiles/players/gemma-font"),
  require("./playerProfiles/players/jennifer-falk"),
  require("./playerProfiles/players/ellie-carpenter"),
  require("./playerProfiles/players/sílvia-rebelo"),
  require("./playerProfiles/players/alice-bergström"),
  require("./playerProfiles/players/daniëlle-van-de-donk"),
  require("./playerProfiles/players/giulia-dragoni"),
  require("./playerProfiles/players/diana-lemešová"),
  require("./playerProfiles/players/daniela-silva"),
  require("./playerProfiles/players/clarissa-larisey"),
  require("./playerProfiles/players/naomie-feller"),
  require("./playerProfiles/players/caroline-weir"),
  require("./playerProfiles/players/filippa-curmark"),
  require("./playerProfiles/players/melchie-dumornay"),
  require("./playerProfiles/players/fran-kirby"),
  require("./playerProfiles/players/lindsey-horan"),
  require("./playerProfiles/players/denisa-veselá"),
  require("./playerProfiles/players/maria-brochmann"),
  require("./playerProfiles/players/loes-geurts"),
  require("./playerProfiles/players/vicky-lópez"),
  require("./playerProfiles/players/salma-paralluelo"),
  require("./playerProfiles/players/natalia-piątek"),
  require("./playerProfiles/players/christine-endler"),
  require("./playerProfiles/players/jonna-brengel"),
  require("./playerProfiles/players/julie-dufour"),
  require("./playerProfiles/players/johanna-renmark"),
  require("./playerProfiles/players/maximiliane-rall"),
  require("./playerProfiles/players/amalie-vangsgaard"),
  require("./playerProfiles/players/tamara-morávková"),
  require("./playerProfiles/players/angel-mukasa"),
  require("./playerProfiles/players/franny-černá"),
  require("./playerProfiles/players/michelle-xiao"),
  require("./playerProfiles/players/bente-jansen"),
  require("./playerProfiles/players/lara-prašnikar"),
  require("./playerProfiles/players/camelia-ceasar"),
  require("./playerProfiles/players/géraldine-reuteler"),
  require("./playerProfiles/players/shekiera-martinez"),
  require("./playerProfiles/players/carlotta-wamser"),
  require("./playerProfiles/players/magnaba-folquet"),
  require("./playerProfiles/players/linda-dallmann"),
  require("./playerProfiles/players/eseosa-aigbogun"),
  require("./playerProfiles/players/lily-yohannes"),
  require("./playerProfiles/players/oriane-jean-françois"),
  require("./playerProfiles/players/jackie-groenen"),
  require("./playerProfiles/players/guðrún-arnardóttir"),
  require("./playerProfiles/players/jana-fernández"),
  require("./playerProfiles/players/mária-mikolajová"),
  require("./playerProfiles/players/vanessa-gilles"),
  require("./playerProfiles/players/martina-fernández"),
  require("./playerProfiles/players/ingrid-syrstad-engen"),
  require("./playerProfiles/players/ella-mastrantonio"),
  require("./playerProfiles/players/claudia-wenger"),
  require("./playerProfiles/players/lais-araujo"),
  require("./playerProfiles/players/signe-bruun"),
  require("./playerProfiles/players/katerina-svitkova"),
  require("./playerProfiles/players/olga-carmona"),
  require("./playerProfiles/players/niamh-charles"),
  require("./playerProfiles/players/melween-ndongala"),
  require("./playerProfiles/players/marie-yasmine-alidou"),
  require("./playerProfiles/players/kristína-košíková"),
  require("./playerProfiles/players/jessica-wik"),
  require("./playerProfiles/players/amel-majri"),
  require("./playerProfiles/players/inès-belloumou"),
  require("./playerProfiles/players/emma-jo-anne-cronquist"),
  require("./playerProfiles/players/magdalena-eriksson"),
  require("./playerProfiles/players/daniela-santos"),
  require("./playerProfiles/players/caroline-graham-hansen"),
  require("./playerProfiles/players/gabriela-ślajsová"),
  require("./playerProfiles/players/feerine-belhadj"),
  require("./playerProfiles/players/rikke-nygard"),
  require("./playerProfiles/players/louna-ribadeira"),
  require("./playerProfiles/players/martina-tomaselli"),
  require("./playerProfiles/players/jella-veit"),
  require("./playerProfiles/players/karolína-krivská"),
  require("./playerProfiles/players/irene-paredes"),
  require("./playerProfiles/players/sophie-ingle"),
  require("./playerProfiles/players/claudia-ciccotti"),
  require("./playerProfiles/players/nicole-payne"),
  require("./playerProfiles/players/lisa-löwing"),
  require("./playerProfiles/players/eartha-cumings"),
  require("./playerProfiles/players/melanie-leupolz"),
  require("./playerProfiles/players/marthine-østenstad"),
  require("./playerProfiles/players/marta-torrejón"),
  require("./playerProfiles/players/amalie-eikeland"),
  require("./playerProfiles/players/michaela-khyrová"),
  require("./playerProfiles/players/paulina-dudek"),
  require("./playerProfiles/players/amélia-silva"),
  require("./playerProfiles/players/martina-surnovská"),
  require("./playerProfiles/players/clara-matéo"),
  require("./playerProfiles/players/isabella-obaze"),
  require("./playerProfiles/players/i̇layda-açıkgöz"),
  require("./playerProfiles/players/nycole-raysla"),
  require("./playerProfiles/players/sophie-hillebrand"),
  require("./playerProfiles/players/saki-kumagai"),
  require("./playerProfiles/players/elisa-bartoli"),
  require("./playerProfiles/players/leonarda-balog"),
  require("./playerProfiles/players/pernille-harder"),
  require("./playerProfiles/players/louise-demarest"),
  require("./playerProfiles/players/alexandra-hellekant"),
  require("./playerProfiles/players/thiniba-samoura"),
  require("./playerProfiles/players/jennifer-klein"),
  require("./playerProfiles/players/georgia-stanway"),
  require("./playerProfiles/players/valentina-giacinti"),
]